import React, { Component } from 'react'
import { connect } from 'react-redux'
import deepEqual from 'deep-equal'
import { SortableContainer, SortableElement } from '@adalo/react-sortable-hoc'
import { MultiMenuTrigger } from '@protonapp/react-multi-menu'
import { dataTypes, sourceTypes, bindingTypes } from '@adalo/constants'
import store from 'redux-store'

import { GroupedAccordion } from 'components/Shared/Accordion'
import BindableTextControl, {
  FormulaControl,
} from 'components/Shared/BindableTextControl'
import EmptyState from 'components/Shared/EmptyState'
import Icon, { IconButton } from 'components/Shared/Icon'
import StylesAccordion from 'components/Shared/StylesAccordion'
import ToggleField from 'components/Shared/Forms/ToggleField'

import {
  getCurrentAppId,
  updateObject,
  getComponent,
} from 'ducks/editor/objects'
import {
  getTableOptions,
  getFormReferenceOptions,
  getLibraryBindingSuggestions,
  getLibraryAllBinding,
} from 'ducks/recommender'
import { openAccordion, closeAccordion } from 'ducks/accordions'
import { getDatasourcesObject, getOrderedFields } from 'ducks/apps/datasources'
import { getApp, getAppBranding } from 'ducks/apps'
import {
  getActiveState,
  getTrialState,
  isFeatureEnabled,
} from 'ducks/organizations'
import { getFeatureFlag } from 'ducks/featureFlags'

import { generateHandleTrial } from 'hooks/useHandleTrialOrUpgrade'

import { getCollection } from 'utils/datasources'
import { singularize } from 'utils/strings'
import { calculateLayout } from 'utils/forms'
import { getLabel } from 'utils/sources'
import { getFontWeightOptions, getFontFamilyOptions } from 'utils/type'
import { buildBinding } from 'utils/bindings'
import { verifyXanoApp } from 'utils/externalDatabases'

import IconRow from '../Libraries/IconRow'
import MenuControl from '../Libraries/MenuControl'
import ActionRow from '../Libraries/ActionRow'
import SimpleTextControl from '../Libraries/SimpleTextControl'
import NewTableOption from '../Libraries/NewTableOption'
import ListFilters from '../Libraries/ListFilters'
import SliderControl from '../Libraries/SliderControl'

import ColorPicker from '../ColorPicker'
import SingleNumberControl from '../SingleNumberControl'
import SlideControl from '../SlideControl'
import ShadowControl from '../ShadowControl'
import SelectControl from '../SelectControl'
import GenericInspectRow from '../GenericRow'
import TextControl from '../TextControl'
import InspectRow from '../Row'
import { ShadowControl as DefaultShadowControl } from '../DefaultShadowControl'
import { FontControl } from '../DefaultFontControl'

import ColorDropdown from '../ColorDropdown'
import SelectSort from './SelectSort'

import { THEMES, BEFORE, START_TRIAL, UPGRADE } from '../../../../constants'

import './FormInspect.scss'

const referenceComparator = (ref1, ref2) => {
  if (!ref1 || !ref2 || !ref1.selector || !ref2.selector) {
    return deepEqual(ref1, ref2)
  }

  return deepEqual(ref1.selector, ref2.selector)
}

class FieldItem extends Component {
  handleChange = field => {
    const { fieldIndex, onChange } = this.props

    onChange(fieldIndex, field)
  }

  handleDelete = () => {
    const { fieldIndex, onDelete } = this.props

    onDelete(fieldIndex)
  }

  handleChangeSort = sort => {
    const { field } = this.props

    if (!field?.binding?.source) return

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          sort: {
            ...sort,
            reference: field.binding.source.sort?.reference,
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleChangeSortLocationSource = locationSource => {
    const { field } = this.props
    const { source } = locationSource || {}
    let binding = null

    if (!field?.binding?.source) return

    if (
      source?.type === sourceTypes.FIELD ||
      source?.type === sourceTypes.INPUT
    ) {
      binding = buildBinding(bindingTypes.SET_LOCATION, source)
    }

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          sort: {
            ...field.binding.source.sort,
            reference: {
              fallback: field.binding.source.sort?.reference?.fallback,
              source: { ...source, binding },
            },
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleChangeSortLocationFallback = fallback => {
    const { field } = this.props

    if (!field?.binding?.source) return

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          sort: {
            ...field.binding.source.sort,
            reference: {
              fallback,
              source: field.binding.source.sort?.reference?.source,
            },
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleChangeFilter = filter => {
    const { field } = this.props

    if (!field?.binding?.source) return

    const newField = {
      ...field,
      binding: {
        ...field.binding,
        source: {
          ...field.binding.source,
          options: {
            ...field.binding.source.options,
            filter,
          },
        },
      },
    }

    return this.handleChange(newField)
  }

  handleRequiredToggle = required => {
    return this.handleChange({ required })
  }

  handleRequiredTextChange = e => {
    const { value: requiredText } = e.target

    return this.handleChange({ requiredText })
  }

  renderChildren = () => {
    const { field, appId, object, componentId } = this.props
    let sortValue = null
    let locationSource = null
    let locationFallback = null

    const showPlaceholder =
      [
        dataTypes.TEXT,
        dataTypes.NUMBER,
        dataTypes.PASSWORD,
        dataTypes.LOCATION,
      ].includes(field.type) || field.type.type === 'belongsTo'

    if (field?.binding?.source?.sort) {
      const { reference, ...sort } = field.binding.source.sort
      sortValue = sort

      if (reference) {
        const { source, fallback } = reference
        locationFallback = fallback

        if (source) {
          const { binding, ...location } = source
          locationSource = location
        }
      }
    }

    return (
      <div onMouseDown={this.handleMouseDown}>
        <SimpleTextControl
          displayName="Label"
          value={field.label}
          name="label"
          onChange={this.handleChange}
        />
        {field.type === dataTypes.LOCATION ? (
          <IconRow
            displayName="Icon"
            name="icon"
            value={field.icon}
            onChange={this.handleChange}
          />
        ) : null}
        {showPlaceholder ? (
          <SimpleTextControl
            displayName="Placeholder"
            value={field.placeholder}
            name="placeholder"
            onChange={this.handleChange}
            placeholder="None"
          />
        ) : null}
        {field.type.type === 'belongsTo' && (
          <>
            <div className="child">
              <ListFilters
                appId={appId}
                componentId={componentId}
                object={object}
                binding={field.binding}
                onChange={this.handleChangeFilter}
                addLabel="Add Custom Filter"
                isForm
              />
            </div>
            <SelectSort
              value={sortValue}
              referenceValue={locationSource}
              fallbackValue={locationFallback}
              objectId={object.id}
              binding={field.binding}
              onChange={this.handleChangeSort}
              onChangeLocationSource={this.handleChangeSortLocationSource}
              onChangeLocationFallback={this.handleChangeSortLocationFallback}
            />
          </>
        )}
        {field.type === dataTypes.TEXT ? (
          <MenuControl
            options={[
              { label: 'Single Line', value: false },
              { label: 'Multi-line', value: true },
            ]}
            value={field.multiline || false}
            name="multiline"
            displayName="Input Type"
            onChange={this.handleChange}
          />
        ) : null}
        {field.type === dataTypes.DATE_ONLY ? (
          <MenuControl
            options={[
              { label: 'Date Picker', value: dataTypes.DATE_ONLY },
              { label: 'Date Text Input', value: 'dateTextInput' },
            ]}
            value={field.datePickerStyle || dataTypes.DATE_ONLY}
            name="datePickerStyle"
            displayName="Date Picker Style"
            onChange={this.handleChange}
          />
        ) : null}
        <ToggleField
          label="Required Error Text"
          value={field.required}
          inputValue={
            field.required ? field?.requiredText || '' : 'Not Required'
          }
          onChange={this.handleRequiredToggle}
          onInputChange={field.required ? this.handleRequiredTextChange : null}
          boxed
          hideLabelOnDisabled
        />
      </div>
    )
  }

  getCollectionField = () => {
    const { field, datasourceCollection } = this.props

    return datasourceCollection.fields[field.fieldId]
  }

  renderTitle = () => {
    const { onDelete } = this.props
    const field = this.getCollectionField()

    return (
      <div className="form-inspect-field-title">
        <span>{(field && field.name) || '[Deleted Column]'}</span>
        {onDelete ? (
          <IconButton type="trash-small" onClick={this.handleDelete} />
        ) : null}
      </div>
    )
  }

  render() {
    const { field, datasourceCollection } = this.props

    if (!field || !datasourceCollection) {
      return null
    }

    return (
      <div className="form-inspect-field-wrapper">
        <GroupedAccordion
          boxed
          className="form-inspect-field"
          group="form-inspect-field"
          itemId={field.fieldId}
          title={this.renderTitle()}
          renderChildren={() => this.renderChildren()}
        />
      </div>
    )
  }
}

const SortableFieldItem = SortableElement(FieldItem)

class FieldItemsList extends Component {
  render() {
    const {
      items,
      collection,
      datasource,
      onChange,
      onDelete,
      appId,
      object,
      componentId,
    } = this.props

    if (!datasource) {
      return null
    }

    return (
      <div>
        {items.map((field, i) => (
          <SortableFieldItem
            key={field.fieldId}
            appId={appId}
            object={object}
            componentId={componentId}
            fieldIndex={i}
            index={i}
            field={field}
            datasource={datasource}
            datasourceCollection={collection}
            onChange={onChange}
            onDelete={onDelete}
          />
        ))}
      </div>
    )
  }
}

const SortableFieldItemsList = SortableContainer(FieldItemsList)

class SubmitButton extends Component {
  static defaultProps = {
    value: {},
  }

  renderStyles = () => {
    const { value, onChange, branding, hasNewEditStyles } = this.props

    return (
      <div>
        {hasNewEditStyles
          ? [
              <GenericInspectRow
                className="default-font-control"
                key="fontControl"
              >
                <ColorDropdown
                  label="Fill Color"
                  value={value.backgroundColor ?? '#60f'}
                  name="backgroundColor"
                  onChange={onChange}
                />
                <ColorDropdown
                  label="Text Color"
                  value={value.color ?? '#fff'}
                  name="color"
                  onChange={onChange}
                />
              </GenericInspectRow>,
              <FontControl
                object={{
                  fontSize: value.fontSize ?? 14,
                  fontWeight: value.fontWeight ?? '400',
                  fontFamily: value.fontFamily ?? '@body',
                }}
                onChange={onChange}
                features={{
                  fontSize: true,
                  fontWeight: true,
                  fontFamily: true,
                }}
                fontWeightOptions={getFontWeightOptions(
                  value.fontFamily,
                  branding
                )}
                fontFamilyOptions={getFontFamilyOptions(branding)}
              />,
              <GenericInspectRow key="padding">
                <SliderControl
                  value={value.padding ?? 10}
                  name="padding"
                  onChange={onChange}
                  title="Padding"
                  min={0}
                  max={40}
                  sliderBackground
                />
              </GenericInspectRow>,
              <GenericInspectRow key="rounding">
                <SliderControl
                  value={value.borderRadius ?? 0}
                  name="borderRadius"
                  onChange={onChange}
                  title="Rounding"
                  min={0}
                  max={50}
                  sliderBackground
                />
              </GenericInspectRow>,
              <GenericInspectRow className="default-font-control">
                <ColorDropdown
                  label="Border Color"
                  value={value.borderColor ?? '#999'}
                  name="borderColor"
                  onChange={onChange}
                />
                <TextControl
                  gray
                  type="number"
                  name="borderWidth"
                  value={value.borderWidth ?? 0}
                  onChange={onChange}
                  title="Border Width"
                  minValue={0}
                  maxValue={6}
                />
              </GenericInspectRow>,
              <DefaultShadowControl object={value} onChange={onChange} />,
            ]
          : // Old Edit Styles
            // TODO: Remove this once we launch the new edit styles
            [
              <InspectRow labeled title="Color">
                <ColorPicker
                  name="backgroundColor"
                  value={value.backgroundColor || '#60f'}
                  onChange={onChange}
                  label="Fill"
                />
                <ColorPicker
                  name="color"
                  value={value.color || '#fff'}
                  onChange={onChange}
                  label="Text"
                />
              </InspectRow>,
              [
                <InspectRow title="Font">
                  <SingleNumberControl
                    value={value.fontSize}
                    defaultValue={14}
                    name="fontSize"
                    onChange={onChange}
                    minValue={8}
                    maxValue={36}
                    label="Size"
                  />
                  <SelectControl
                    value={value.fontWeight}
                    defaultValue={400}
                    name="fontWeight"
                    onChange={onChange}
                    options={getFontWeightOptions(value.fontFamily, branding)}
                  />
                </InspectRow>,
                <InspectRow>
                  <SelectControl
                    value={value.fontFamily}
                    defaultValue="@body"
                    name="fontFamily"
                    onChange={onChange}
                    options={getFontFamilyOptions(branding)}
                  />
                </InspectRow>,
              ],
              <InspectRow title="Padding">
                <SingleNumberControl
                  value={value.padding}
                  defaultValue={10}
                  name="padding"
                  onChange={onChange}
                  minValue={0}
                  maxValue={40}
                />
                <span />
              </InspectRow>,
              <InspectRow title="Rounding">
                <SlideControl
                  value={value.borderRadius || 0}
                  name="borderRadius"
                  onChange={onChange}
                  min={0}
                  max={50}
                />
              </InspectRow>,
              <InspectRow labeled title="Border">
                <ColorPicker
                  value={value.borderColor}
                  onChange={onChange}
                  defaultValue="#999"
                  name="borderColor"
                  label="Color"
                />
                <SingleNumberControl
                  value={value.borderWidth}
                  onChange={onChange}
                  defaultValue={0}
                  name="borderWidth"
                  label="Size"
                  minValue={0}
                  maxValue={6}
                />
              </InspectRow>,
              <InspectRow labeled title="Shadow">
                <ShadowControl
                  name="shadow"
                  value={value.shadow}
                  onChange={onChange}
                />
              </InspectRow>,
            ]}
      </div>
    )
  }

  handleChangeAdvanced = val => {
    const { onChange } = this.props

    onChange(val)
  }

  getDummyActions() {
    const {
      object: { reference, id },
      tableName,
      onChange,
    } = this.props

    let title
    let sentence

    if (reference === 'new') {
      title = 'Create'
      sentence = `create the ${tableName}`
    } else if (reference === 'login') {
      title = 'Log In'
      sentence = `log in the ${tableName}`
    } else if (reference === 'signup') {
      title = 'Sign Up'
      sentence = `sign up the ${tableName}`
    } else {
      title = 'Update'
      sentence = `update a ${tableName}`
    }

    return [
      {
        id: 'submit',
        title,
        subtitle: tableName,
        description: `Clicking the Submit Button on the form will ${sentence}`,
        reference,
        objectId: id,
        onChange,
      },
    ]
  }

  render() {
    const { value, onChange, object, appId } = this.props

    return (
      <div>
        <SimpleTextControl
          displayName="Text"
          name="text"
          value={value.text}
          placeholder="SAVE"
          onChange={onChange}
        />
        <ActionRow
          key={object.id}
          appId={appId}
          displayName="Click Actions"
          name="action"
          value={value.action}
          onChange={onChange}
          objectId={object.id}
          dummyActions={this.getDummyActions()}
        />
        <StylesAccordion renderChildren={this.renderStyles} />
      </div>
    )
  }
}

class HiddenField extends Component {
  handleChange = value => {
    const { index, onChange } = this.props

    if (value && 'value' in value) {
      value = value.value
    }

    onChange(index, { value })
  }

  handleDelete = () => {
    const { index, onDelete } = this.props

    onDelete(index)
  }

  getCollectionField = () => {
    const { field, collection } = this.props

    return collection.fields[field.fieldId]
  }

  renderTitle = () => {
    const { onDelete, field, getLabel } = this.props
    let collectionField = this.getCollectionField()

    if (!collectionField) {
      collectionField = { name: '[Deleted]' }
    }

    return (
      <div className="form-inspect-field-title form-inspect-hidden-field-title">
        <div className="form-inspect-field-title-sub">
          <p>{collectionField.name}</p>
          <p className="form-inspect-field-subtitle">{getLabel(field.value)}</p>
        </div>
        {onDelete ? (
          <IconButton type="trash-small" onClick={this.handleDelete} />
        ) : null}
      </div>
    )
  }

  renderChildren = () => {
    const { field, options, getLabel, object } = this.props
    const { value } = field

    switch (field.type) {
      case dataTypes.TEXT:
      case dataTypes.PASSWORD:
        return (
          <div className="form-inspect-hidden-field-value">
            <BindableTextControl
              displayName="Value"
              name="value"
              value={value}
              onChange={this.handleChange}
              comparator={deepEqual}
              getLabel={getLabel}
              objectId={object.id}
            />
          </div>
        )
      case dataTypes.NUMBER:
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return (
          <div className="form-inspect-hidden-field-value">
            <FormulaControl
              dataType={field.type}
              displayName="Value"
              name="value"
              value={value}
              onChange={this.handleChange}
              comparator={deepEqual}
              getLabel={getLabel}
              objectId={object.id}
              placeholder="0"
            />
          </div>
        )
      case dataTypes.BOOLEAN:
      default:
        return (
          <div className="form-inspect-hidden-field-value">
            <MenuControl
              options={options}
              name="value"
              displayName="Value"
              value={value}
              getLabel={getLabel}
              comparator={deepEqual}
              onChange={this.handleChange}
            />
          </div>
        )
    }
  }

  render() {
    const { field } = this.props

    return (
      <div className="form-inspect-field-wrapper">
        <GroupedAccordion
          boxed
          className="form-inspect-field"
          group="form-inspect-field"
          itemId={field.fieldId}
          title={this.renderTitle()}
          renderChildren={this.renderChildren}
        />
      </div>
    )
  }
}

const hiddenFieldMapState = (state, { field, object, appId, componentId }) => ({
  options: getLibraryBindingSuggestions(state, appId, componentId, object.id, [
    field.type,
  ]),
  getLabel: val => getLabel(state, val, appId, componentId),
})

const WrappedHiddenField = connect(hiddenFieldMapState)(HiddenField)

/**
 * @typedef {Object} Props
 * @property {import('utils/responsiveTypes').EditorObject} object
 * @property {import('ducks/apps/App').App} app
 * @property {Function} updateObject
 * @property {object} datasources
 * @property {'new' | 'login' | 'signup'} reference
 * @property {object} collection
 * @property {string} appId
 * @property {string} componentId
 * @property {boolean} isLocationEnabled
 * @property {*} trialState
 * @property {boolean} isPaidOrg
 * @property {*} handleTrial
 * @property {object} branding
 * @property {Function} openAccordion
 */

class FormInspect extends Component {
  /**
   *
   * @param {Partial<import('utils/responsiveTypes').EditorObject>} changes
   */
  update = changes => {
    /** @type {Props} */
    const { object, app, updateObject } = this.props

    if (app?.magicLayout === true) {
      delete changes.height
    }

    updateObject(object.id, changes)
  }

  handleChangeReference = ({ reference }) => {
    /** @type {Props} */
    const { object, datasources } = this.props
    let { collection, submitButton, fields, height } = object

    submitButton = {
      ...submitButton,
      text: this.getSubmitButtonText(collection, reference),
    }

    const datasource = datasources[collection.datasourceId]
    const authTable = datasource.auth && datasource.auth.table

    if (authTable === collection.tableId) {
      fields = this.getFields(collection, reference)
      height = this.getHeight({ fields })
    }

    this.update({ reference, submitButton, fields, height })
  }

  handleChangeTable = opts => {
    /** @type {Props} */
    const { datasources } = this.props

    // When a new table is created, opts should return with a source
    // We need to set the opts.collection before the undefined check
    if (opts.source) {
      const { datasourceId, tableId } = opts.source.source
      opts.collection = { datasourceId, tableId }
    }

    // if true, should be a new table
    if (
      opts.collection === undefined ||
      opts.collection.datasourceId === undefined
    ) {
      return
    }

    /** @type {Props} */
    let { object, reference, collection } = this.props
    let { submitButton } = object

    const datasource = datasources[opts.collection.datasourceId]
    const authTable = datasource.auth && datasource.auth.table

    if (!reference || opts.collection !== collection) {
      if (authTable === opts.collection.tableId) {
        reference = 'login'
      } else {
        reference = 'new'
      }
    }

    const fields = this.getFields(opts.collection, reference)
    const hiddenFields = this.getHiddenFields(opts.collection)
    const height = this.getHeight({ fields })

    submitButton = {
      ...submitButton,
      text: this.getSubmitButtonText(opts.collection, reference),
    }

    this.update({
      fields,
      hiddenFields,
      height,
      reference,
      submitButton,
      collection: opts.collection,
    })
  }

  getSubmitButtonText = (collection, reference) => {
    /** @type {Props} */
    const { datasources } = this.props
    const datasource = datasources[collection.datasourceId]
    let table = null

    if (collection.tableId) {
      table = datasource.tables[collection.tableId]
    } else {
      table = datasource.collections[collection.collectionId]
    }

    const tableName = singularize(table.name)

    let result = `Update ${tableName}`

    if (reference === 'new') {
      result = `Create ${tableName}`
    } else if (reference === 'login') {
      result = 'Login'
    } else if (reference === 'signup') {
      result = 'Signup'
    }

    return result.toUpperCase()
  }

  getHeight = changes => {
    /** @type {Props} */
    const { object } = this.props
    const layout = calculateLayout({ ...object, ...changes })

    return layout.height
  }

  getFields = (collection, reference) => {
    /** @type {Props} */
    const { datasources } = this.props
    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const result = []

      let fields = getOrderedFields(table)

      if (reference === 'login') {
        fields = ['email', 'password']
      }

      for (const fieldId of fields) {
        const field = table.fields[fieldId]

        if (typeof field.type !== 'string') {
          continue
        }

        result.push(this.getField(fieldId, collection))
      }

      return result
    } else {
      // API
      // TODO: implement properly
      // let collectionObj = datasource.collections[collection.collectionId]
      return []
    }
  }

  getHiddenFields = collection => {
    /** @type {Props} */
    const { datasources } = this.props
    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const result = []

      for (const fieldId of getOrderedFields(table)) {
        const field = table.fields[fieldId]

        if (field.type.type !== 'belongsTo') {
          continue
        }

        result.push(this.getHiddenField(fieldId, collection))
      }

      return result
    } else {
      // API
      // TODO: implement properly
      // let collectionObj = datasource.collections[collection.collectionId]
      return []
    }
  }

  getField = (fieldId, collection) => {
    /** @type {Props} */
    const { object, datasources } = this.props

    if (!collection) {
      collection = object.collection
    }

    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const field = table.fields[fieldId]

      const verb =
        field.type === dataTypes.DATE || field.type.type === 'belongsTo'
          ? 'Select'
          : 'Enter'

      let placeholder = `${verb} ${(field.name || '').toLowerCase()}...`
      let requiredText = 'This field is required.'
      let icon

      if (field.type === dataTypes.LOCATION) {
        placeholder = 'Search by name or address...'
        requiredText = 'Please select a valid location from the dropdown menu.'
        icon = 'location_on'
      }

      return {
        fieldId,
        label: field.name,
        placeholder,
        type: field.type,
        required: true,
        requiredText,
        icon,
      }
    } else {
      // API
      // TODO: implement properly
      throw new Error('APIs not yet supported')
    }
  }

  getHiddenField = (fieldId, collection) => {
    /** @type {Props} */
    const { appId, componentId, object, datasources } = this.props

    if (!collection) {
      collection = object.collection
    }

    const datasource = datasources[collection.datasourceId]

    if (collection.tableId) {
      // Table
      const table = datasource.tables[collection.tableId]
      const field = table.fields[fieldId]
      let value

      if (field.type.type === 'belongsTo') {
        const state = store.getState()

        const options = getLibraryBindingSuggestions(
          state,
          appId,
          componentId,
          object.id,
          [field.type]
        )()

        const option = options.filter(opt => opt && opt.value)[0]

        value = option && option.value
      }

      return {
        fieldId,
        value,
        type: field.type,
      }
    } else {
      // API
      // TODO: implement properly
      throw new Error('APIs not yet supported')
    }
  }

  getAddHiddenFieldOptions = () => {
    return this.getAddFieldOptions({ hidden: true })
  }

  getAddFieldOptions = (opts = {}) => {
    /** @type {Props} */
    const {
      object,
      datasources,
      isLocationEnabled,
      trialState,
      isPaidOrg,
      handleTrial,
      appId,
    } = this.props

    let { fields, hiddenFields, collection } = object

    if (opts.hidden) {
      fields = hiddenFields
    }

    fields = fields || []

    const datasource = datasources[collection.datasourceId]
    const existingFields = fields.map(f => f.fieldId)

    const result = []

    // API
    if (!collection.tableId) {
      // TODO: implement properly
      // let collectionObj = datasource.collections[collection.collectionId]
      return result
    }

    // Table
    const table = datasource.tables[collection.tableId]

    for (const fieldId of getOrderedFields(table)) {
      const field = table.fields[fieldId]

      // Exclude many-to-many relationships
      if (
        existingFields.includes(fieldId) ||
        field?.type?.type === 'manyToMany'
      ) {
        continue
      }

      // Disable and show an upsell CTA for location fields
      if (field?.type === dataTypes.LOCATION && !isLocationEnabled) {
        const hoverContent =
          trialState === BEFORE && !isPaidOrg ? START_TRIAL : UPGRADE

        result.push({
          label: field.name,
          locked: true,
          rightIcon: <Icon type="lock-small" small />,
          onClick: () => handleTrial(trialState, isPaidOrg, appId),
          hoverContent,
        })

        continue
      }

      result.push({
        label: field.name,
        value: opts.hidden
          ? this.getHiddenField(fieldId, collection)
          : this.getField(fieldId, collection),
      })
    }

    return result
  }

  // Create, update, delete fields

  handleAddField = field => {
    /** @type {Props} */
    const { object, openAccordion, datasources } = this.props
    let { fields, hiddenFields } = object

    if (field.type.type === 'belongsTo') {
      const { datasourceId, tableId } = field.type
      const datasource = datasources[datasourceId]
      const table = datasource.tables[tableId]

      const labelField = Object.keys(table.fields).filter(
        f => table.fields[f].isPrimaryField
      )[0]

      field = {
        ...field,
        binding: getLibraryAllBinding(datasourceId, tableId, { labelField }),
      }
    }

    hiddenFields = hiddenFields.filter(f => f.fieldId !== field.fieldId)
    fields = fields.concat([field])
    const height = this.getHeight({ fields })

    openAccordion('form-inspect-field', field.fieldId)
    this.update({ fields, hiddenFields, height })
  }

  handleChangeField = (index, field) => {
    /** @type {Props} */
    const { object } = this.props
    let { fields } = object

    fields = fields.slice()
    fields.splice(index, 1, { ...fields[index], ...field })

    const height = this.getHeight({ fields })

    this.update({ fields, height })
  }

  handleDeleteField = index => {
    /** @type {Props} */
    const { object } = this.props
    let { fields } = object

    fields = fields.slice()
    fields.splice(index, 1)
    const height = this.getHeight({ fields })

    this.update({ fields, height })
  }

  // Create, update, delete hidden fields

  handleAddHiddenField = field => {
    /** @type {Props} */
    const { object, openAccordion } = this.props
    let { fields } = object
    let hiddenFields = object.hiddenFields || []

    fields = fields.filter(f => f.fieldId !== field.fieldId)
    hiddenFields = hiddenFields.concat([field])
    const height = this.getHeight({ fields })

    openAccordion('form-inspect-field', field.fieldId)
    this.update({ hiddenFields, fields, height })
  }

  handleChangeHiddenField = (index, field) => {
    /** @type {Props} */
    const { object } = this.props
    let { hiddenFields } = object

    hiddenFields = hiddenFields.slice()
    hiddenFields.splice(index, 1, { ...hiddenFields[index], ...field })

    this.update({ hiddenFields })
  }

  handleDeleteHiddenField = index => {
    /** @type {Props} */
    const { object } = this.props
    let { hiddenFields } = object

    hiddenFields = hiddenFields.slice()
    hiddenFields.splice(index, 1)

    this.update({ hiddenFields })
  }

  handleUpdateSubmit = (value, actionChanges) => {
    /** @type {Props} */
    const { object } = this.props
    let { actions, submitButton } = object

    submitButton = { ...submitButton, ...value }

    const height = this.getHeight({ submitButton })

    this.update({
      submitButton,
      height,
      actions: { ...actions, ...actionChanges },
    })
  }

  getCollectionName = () => {
    /** @type {Props} */
    const { object, datasources } = this.props
    const { collection } = object

    const datasource = datasources[collection.datasourceId]
    const datasourceCollection = getCollection(datasource, collection)

    return datasourceCollection.name
  }

  handleSortFields = ({ oldIndex, newIndex }) => {
    /** @type {Props} */
    const { object } = this.props

    const fields = object.fields.slice()
    const field = fields[oldIndex]
    fields.splice(oldIndex, 1)
    fields.splice(newIndex, 0, field)

    this.update({ fields })
  }

  renderFields() {
    /** @type {Props} */
    const { object, datasources, appId, componentId } = this.props
    let { collection, fields, hiddenFields, reference } = object

    fields = fields || []
    hiddenFields = hiddenFields || []

    if (!collection || !reference) {
      return null
    }

    const datasource = datasources[collection.datasourceId]
    const datasourceCollection = getCollection(datasource, collection)

    if (!datasource || !datasourceCollection) {
      return null
    }

    return (
      <GroupedAccordion
        className="library-inspect-accordion"
        group="form-inspect"
        title="Fields"
        renderChildren={() => (
          <div>
            <div className="form-inspect-fields-section">
              <p className="form-inspect-fields-title">Form Fields</p>
              {fields.length === 0 ? (
                <EmptyState>No Visible Fields</EmptyState>
              ) : (
                <SortableFieldItemsList
                  items={fields}
                  collection={datasourceCollection}
                  datasource={datasource}
                  appId={appId}
                  componentId={componentId}
                  object={object}
                  onChange={this.handleChangeField}
                  onDelete={this.handleDeleteField}
                  onSortEnd={this.handleSortFields}
                  distance={5}
                />
              )}
              <MultiMenuTrigger
                className="form-inspect-add-field"
                menu={this.getAddFieldOptions}
                onSelect={this.handleAddField}
                menuTheme={THEMES.DATA}
              >
                <Icon type="plus" />
                <span>Add Visible Field</span>
              </MultiMenuTrigger>
            </div>
            <div className="form-inspect-fields-section">
              <p className="form-inspect-fields-title">Set Automatically</p>
              {hiddenFields.length === 0 ? (
                <EmptyState>No Automatic Fields</EmptyState>
              ) : (
                hiddenFields.map((field, i) => (
                  <WrappedHiddenField
                    key={field.fieldId}
                    field={field}
                    index={i}
                    object={object}
                    appId={appId}
                    componentId={componentId}
                    collection={datasourceCollection}
                    datasource={datasource}
                    onChange={this.handleChangeHiddenField}
                    onDelete={this.handleDeleteHiddenField}
                  />
                ))
              )}
              <MultiMenuTrigger
                className="form-inspect-add-field"
                menu={this.getAddHiddenFieldOptions}
                onSelect={this.handleAddHiddenField}
              >
                <Icon type="plus" />
                <span>Add Automatic Field</span>
              </MultiMenuTrigger>
            </div>
            <StylesAccordion renderChildren={this.renderFieldStyles} />
          </div>
        )}
      />
    )
  }

  handleChangeFieldStyles = namespace => changes => {
    /** @type {Props} */
    const { object } = this.props
    let { fieldStyles } = object
    fieldStyles = fieldStyles || {}

    const prevValue = namespace ? fieldStyles[namespace] || {} : fieldStyles

    let styles = { ...prevValue, ...changes }

    if (namespace) {
      styles = { ...fieldStyles, [namespace]: styles }
    }

    const height = this.getHeight({ fieldStyles: styles })
    this.update({ fieldStyles: styles, height })
  }

  getFieldStyles = namespace => {
    /** @type {Props} */
    const { object } = this.props
    let { fieldStyles } = object

    if (!fieldStyles) {
      fieldStyles = {}
    }

    if (namespace) {
      return fieldStyles[namespace] || {}
    }

    return fieldStyles
  }

  renderFieldStyles = () => {
    /** @type {Props} */
    const { branding, hasNewEditStyles } = this.props

    return (
      <div>
        {hasNewEditStyles && [
          <GenericInspectRow className="default-font-control" key="fontControl">
            <TextControl
              gray
              type="number"
              name="fontSize"
              value={this.getFieldStyles('inputs').fontSize ?? 16}
              onChange={this.handleChangeFieldStyles('inputs')}
              title="Inputs Size"
              minValue={8}
              maxValue={36}
            />
            <TextControl
              gray
              type="number"
              name="fontSize"
              value={this.getFieldStyles('labels').fontSize ?? 14}
              onChange={this.handleChangeFieldStyles('labels')}
              title="Labels Size"
              minValue={8}
              maxValue={36}
            />
          </GenericInspectRow>,
          <MenuControl
            displayName="Inputs Font Family"
            name="fontFamily"
            value={this.getFieldStyles('inputs').fontFamily}
            onChange={this.handleChangeFieldStyles('inputs')}
            options={getFontFamilyOptions(branding)}
            className="font-family-control"
          />,
          <MenuControl
            displayName="Labels Font Family"
            name="fontFamily"
            value={this.getFieldStyles('labels').fontFamily}
            onChange={this.handleChangeFieldStyles('labels')}
            options={getFontFamilyOptions(branding)}
            className="font-family-control"
          />,
          <GenericInspectRow className="default-font-control">
            <ColorDropdown
              label="Inputs Color"
              value={this.getFieldStyles('inputs').color ?? '#000'}
              name="color"
              onChange={this.handleChangeFieldStyles('inputs')}
            />
            <ColorDropdown
              label="Labels Color"
              value={this.getFieldStyles('labels').color ?? '#000'}
              name="color"
              onChange={this.handleChangeFieldStyles('labels')}
            />
          </GenericInspectRow>,
          <GenericInspectRow className="default-font-control">
            <ColorDropdown
              label="Placeholder Color"
              value={this.getFieldStyles('inputs').placeholderColor ?? '#aaa'}
              name="placeholderColor"
              onChange={this.handleChangeFieldStyles('inputs')}
            />
            <ColorDropdown
              label="Fill Color"
              value={this.getFieldStyles('inputs').backgroundColor ?? '#fff'}
              name="backgroundColor"
              onChange={this.handleChangeFieldStyles('inputs')}
            />
          </GenericInspectRow>,
          <GenericInspectRow className="default-font-control">
            <ColorDropdown
              label="Accent Color"
              value={this.getFieldStyles('inputs').accentColor ?? '@secondary'}
              name="accentColor"
              onChange={this.handleChangeFieldStyles('inputs')}
            />
            <ColorDropdown
              label="Error Color"
              value={this.getFieldStyles('inputs').errorColor ?? '#f00'}
              name="errorColor"
              onChange={this.handleChangeFieldStyles('inputs')}
            />
          </GenericInspectRow>,
          <GenericInspectRow className="default-font-control">
            <ColorDropdown
              label="Border Color"
              value={this.getFieldStyles('inputs').borderColor ?? '#ddd'}
              name="borderColor"
              onChange={this.handleChangeFieldStyles('inputs')}
            />
            <TextControl
              gray
              type="number"
              name="borderWidth"
              value={this.getFieldStyles('inputs').borderWidth ?? 1}
              onChange={this.handleChangeFieldStyles('inputs')}
              title="Border Size"
              minValue={0}
              maxValue={6}
            />
          </GenericInspectRow>,
          <GenericInspectRow className="default-font-control">
            <TextControl
              gray
              type="number"
              name="padding"
              value={this.getFieldStyles('inputs').padding ?? 10}
              onChange={this.handleChangeFieldStyles('inputs')}
              title="Padding"
              minValue={0}
              maxValue={40}
            />
          </GenericInspectRow>,
          <SliderControl
            name="borderRadius"
            value={this.getFieldStyles('inputs').borderRadius ?? 4}
            onChange={this.handleChangeFieldStyles('inputs')}
            title="Rounding"
            min={0}
            max={50}
            sliderBackground
          />,
          <DefaultShadowControl
            name="shadow"
            object={this.getFieldStyles('inputs')}
            onChange={this.handleChangeFieldStyles('inputs')}
          />,
        ]}
        {!hasNewEditStyles && (
          <InspectRow labeled title="Fonts">
            <SingleNumberControl
              value={this.getFieldStyles('inputs').fontSize}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue={16}
              name="fontSize"
              label="Inputs Size"
              minValue={8}
              maxValue={36}
            />
            <SingleNumberControl
              value={this.getFieldStyles('labels').fontSize}
              onChange={this.handleChangeFieldStyles('labels')}
              defaultValue={14}
              name="fontSize"
              label="Labels Size"
              minValue={8}
              maxValue={36}
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && [
          <InspectRow>
            <SelectControl
              value={this.getFieldStyles('inputs').fontFamily}
              defaultValue="@body"
              name="fontFamily"
              label="Inputs Font Family"
              onChange={this.handleChangeFieldStyles('inputs')}
              options={getFontFamilyOptions(branding)}
            />
          </InspectRow>,
          <InspectRow>
            <SelectControl
              value={this.getFieldStyles('labels').fontFamily}
              defaultValue="@body"
              name="fontFamily"
              label="Labels Font Family"
              onChange={this.handleChangeFieldStyles('labels')}
              options={getFontFamilyOptions(branding)}
            />
          </InspectRow>,
        ]}
        {!hasNewEditStyles && (
          <InspectRow labeled title="Color">
            <ColorPicker
              value={this.getFieldStyles('inputs').color}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue="#000"
              name="color"
              label="Inputs"
            />
            <ColorPicker
              value={this.getFieldStyles('labels').color}
              onChange={this.handleChangeFieldStyles('labels')}
              defaultValue="#000"
              name="color"
              label="Labels"
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && (
          <InspectRow title="">
            <ColorPicker
              value={this.getFieldStyles('inputs').placeholderColor}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue="#aaa"
              name="placeholderColor"
              label="Placeholder"
            />
            <ColorPicker
              value={this.getFieldStyles('inputs').backgroundColor}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue="#fff"
              name="backgroundColor"
              label="Fill"
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && (
          <InspectRow title="">
            <ColorPicker
              value={this.getFieldStyles('inputs').accentColor}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue="@secondary"
              name="accentColor"
              label="Accents"
            />
            <span />
          </InspectRow>
        )}
        {!hasNewEditStyles && (
          <InspectRow labeled title="Border">
            <ColorPicker
              value={this.getFieldStyles('inputs').borderColor}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue="#ddd"
              name="borderColor"
              label="Color"
            />
            <SingleNumberControl
              value={this.getFieldStyles('inputs').borderWidth}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue={1}
              name="borderWidth"
              label="Size"
              minValue={0}
              maxValue={6}
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && (
          <InspectRow title="">
            <ColorPicker
              value={this.getFieldStyles('inputs').errorColor}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue="red"
              name="errorColor"
              label="Error"
            />
            <span />
          </InspectRow>
        )}
        {!hasNewEditStyles && (
          <InspectRow title="Padding">
            <SingleNumberControl
              value={this.getFieldStyles('inputs').padding}
              onChange={this.handleChangeFieldStyles('inputs')}
              defaultValue={10}
              name="padding"
              minValue={0}
              maxValue={40}
            />
            <span />
          </InspectRow>
        )}
        {!hasNewEditStyles && (
          <InspectRow title="Rounding">
            <SlideControl
              name="borderRadius"
              value={this.getFieldStyles('inputs').borderRadius}
              defaultValue={4}
              onChange={this.handleChangeFieldStyles('inputs')}
              min={0}
              max={50}
            />
          </InspectRow>
        )}
        {!hasNewEditStyles && (
          <InspectRow labeled title="Shadow">
            <ShadowControl
              name="shadow"
              value={this.getFieldStyles('inputs').shadow}
              onChange={this.handleChangeFieldStyles('inputs')}
            />
          </InspectRow>
        )}
      </div>
    )
  }

  renderSubmitButton() {
    /** @type {Props} */
    const { object, datasources, appId, branding, hasNewEditStyles } =
      this.props
    const { collection, fields, reference } = object

    if (!collection || !fields || !reference) {
      return null
    }

    const datasource = datasources[collection.datasourceId]
    const datasourceCollection = getCollection(datasource, collection)

    if (!datasource || !datasourceCollection) {
      return null
    }

    const table = collection.tableId
      ? datasource.tables[collection.tableId]
      : datasource.collections[collection.collectionId]

    return (
      <GroupedAccordion
        className="library-inspect-accordion"
        group="form-inspect"
        title="Submit Button"
        renderChildren={() => (
          <SubmitButton
            appId={appId}
            object={object}
            tableName={singularize(table.name)}
            onChange={this.handleUpdateSubmit}
            value={object.submitButton}
            branding={branding}
            hasNewEditStyles={hasNewEditStyles}
          />
        )}
      />
    )
  }

  addNew(tableOptions) {
    const newOptions = {
      label: <NewTableOption handleChangeTable={this.handleChangeTable} />,
      value: {},
    }

    return tableOptions.concat([null, newOptions])
  }

  render() {
    const { datasources, object, tableOptions, referenceOptions, app } =
      this.props
    const { collection, reference } = object

    let options = tableOptions

    const isXanoApp = verifyXanoApp(app)

    if (
      datasources &&
      Object.keys(datasources)
        .map(id => datasources[id].type !== 'api')
        .includes(true) &&
      !isXanoApp
    ) {
      options = this.addNew(tableOptions)
    }

    return (
      <div>
        <GroupedAccordion
          defaultExpanded
          className="library-inspect-accordion"
          group="form-inspect"
          title="Form"
          renderChildren={() => (
            <React.Fragment>
              <MenuControl
                displayName="Which data collection?"
                name="collection"
                value={collection}
                onChange={this.handleChangeTable}
                options={options}
                comparator={deepEqual}
                placeholder="Select collection..."
                menuTheme={THEMES.DATA}
              />
              {collection ? (
                <MenuControl
                  displayName="What do you want the form to do?"
                  name="reference"
                  value={reference}
                  comparator={referenceComparator}
                  onChange={this.handleChangeReference}
                  options={referenceOptions}
                  menuTheme={THEMES.ACTION}
                />
              ) : null}
            </React.Fragment>
          )}
        />
        {this.renderFields()}
        {this.renderSubmitButton()}
      </div>
    )
  }
}

const mapStateToProps = (state, { object }) => {
  const appId = getCurrentAppId(state)
  const app = getApp(state, appId)
  const component = getComponent(state, object.id)
  const { collection } = object

  const isLocationEnabled = isFeatureEnabled(state, 'geolocation')
  const isPaidOrg = getActiveState(state)
  const { trialState } = getTrialState(state)
  const hasNewEditStyles = getFeatureFlag(state, 'hasNewEditStyles')

  const branding = getAppBranding(state, appId)

  return {
    app,
    appId,
    branding,
    componentId: component.id,
    tableOptions: getTableOptions(state, appId),
    datasources: getDatasourcesObject(state, appId),
    referenceOptions: getFormReferenceOptions(
      state,
      appId,
      object.id,
      collection
    ),
    isLocationEnabled,
    isPaidOrg,
    trialState,
    hasNewEditStyles,
  }
}

const mapDispatchToProps = dispatch => {
  const handleTrial = (trialState, isPaidOrg, appId) => {
    const handle = generateHandleTrial({
      appId,
      trialState,
      type: 'geolocation',
      dispatch,
      isPaidOrg,
    })

    handle()
  }

  return {
    handleTrial,
    updateObject: (...props) => dispatch(updateObject(...props)),
    openAccordion: (...props) => dispatch(openAccordion(...props)),
    closeAccordion: (...props) => dispatch(closeAccordion(...props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormInspect)
