import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  dataTypes,
  backgroundStyles,
  borderStyles,
  borderPosition,
  LABEL,
  INPUT,
  SECTION,
  IMAGE_UPLOAD,
  DATE_PICKER,
  FILE_UPLOAD,
  SELECT,
} from '@adalo/constants'
import { connect } from 'react-redux'

import { scaleValue } from 'utils/zoom'
import { calculateLayout, getFormFieldStyle } from 'utils/forms'
import { normalizeColor } from 'utils/colors'

import { getCurrentAppId } from 'ducks/editor/objects'
import { getAppBranding } from 'ducks/apps'

import CanvasObject from '../CanvasObject'
import BaseObject from '../BaseObject'

class Form extends BaseObject {
  static defaultProps = {
    fields: [
      { label: 'Field 1', type: dataTypes.TEXT },
      { label: 'Field 2', type: dataTypes.TEXT },
      { label: 'Field 3', type: dataTypes.TEXT },
    ],
    submitButton: {
      text: 'SAVE',
      color: '@contrast:backgroundColor',
      backgroundColor: '@secondary',
    },
  }

  static childContextTypes = {
    editable: PropTypes.bool,
    branding: PropTypes.object,
  }

  getChildContext = () => {
    const { branding } = this.props

    return {
      editable: false,
      branding,
    }
  }

  getOffset = index => {
    const { zoom } = this.props

    return scaleValue(index * 90, zoom)
  }

  getChildZoom = () => {
    const { zoom } = this.props

    return { ...zoom, offset: [0, 0] }
  }

  renderSubmitButton(layout) {
    const { submitButton, width, editorResizingProps, branding } = this.props
    const childZoom = this.getChildZoom()

    let {
      borderColor,
      borderWidth,
      borderRadius,
      shadow,
      padding,
      fontSize,
      fontWeight,
      fontFamily = '@body',
    } = submitButton

    const text = submitButton.text || 'SAVE'
    const color = normalizeColor(submitButton.color || '#fff', branding)

    const backgroundColor = normalizeColor(
      submitButton.backgroundColor || '@primary',
      branding,
      submitButton
    )

    if (padding === undefined) {
      padding = 10
    }

    if (borderRadius === undefined) {
      borderRadius = 4
    }

    const wrapperX = editorResizingProps ? '50%' : 0
    const textX = editorResizingProps ? -width / 2 : 0
    const resizingProps = editorResizingProps
      ? { ...editorResizingProps, relativeX: 0 }
      : null

    fontSize = fontSize || 14
    borderColor = borderColor || '#999'
    borderWidth = borderWidth || 0
    const height = 2 * padding + 1.4 * fontSize

    return (
      <g>
        <CanvasObject
          zoom={childZoom}
          branding={branding}
          object={{
            id: 'submit',
            type: SECTION,
            x: 0,
            y: layout.button,
            width,
            height,
            backgroundStyle: backgroundStyles.COLOR,
            backgroundColor,
            borderRadius,
            borderStyle: borderStyles.SOLID,
            borderColor,
            borderWidth,
            shadow,
            editorResizingProps: resizingProps,
          }}
        />
        <svg x={wrapperX} y="0" height="100%" width="100%" overflow="visible">
          <CanvasObject
            zoom={childZoom}
            branding={branding}
            object={{
              text,
              color,
              fontSize,
              fontWeight,
              fontFamily,
              width,
              id: 'submit-text',
              layoutText: [text],
              textAlignment: 'center',
              type: LABEL,
              x: textX,
              y: layout.button + padding + 0.05 * fontSize,
              height: fontSize * 1.4,
            }}
          />
        </svg>
      </g>
    )
  }

  render() {
    const {
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      zoom,
      fields,
      submitButton,
      width,
      branding,
      ...obj
    } = this.props

    const childZoom = this.getChildZoom()

    const layout = calculateLayout(this.props)

    const forgotPassword = submitButton.backgroundColor || '@secondary'

    return (
      <g transform={`translate(${xScaled} ${yScaled})`}>
        <g pointerEvents="none">
          {fields.map((field, i) => (
            <FormField
              {...field}
              key={i} // eslint-disable-line
              index={i}
              zoom={childZoom}
              width={width}
              layout={layout.elements[i]}
              object={obj}
              branding={branding}
              forgotPassword={forgotPassword}
            />
          ))}
          {this.renderSubmitButton(layout.elements[fields.length])}
        </g>
        <rect
          stroke="none"
          fill="none"
          x={0}
          y={0}
          width={widthScaled}
          height={heightScaled}
          onMouseDown={this.handleMouseDown}
          onDoubleClick={this.handleDoubleClick}
        />
      </g>
    )
  }
}

export class FormField extends Component {
  static contextTypes = {
    branding: PropTypes.object,
  }

  getStyles = () => {
    return {}
  }

  getInputProps = () => {
    let {
      width,
      index,
      multiline,
      zoom,
      placeholder,
      layout,
      object,
      type,
      icon,
    } = this.props

    const { branding } = this.context
    const { editorResizingProps } = object || {}

    const fieldType = type
    let layerType = INPUT
    let y = layout.input
    const inputFontSize = getFormFieldStyle(object, 16, 'inputs', 'fontSize')
    const inputPadding = getFormFieldStyle(object, 10, 'inputs', 'padding')

    const inputStyles = {
      color: getFormFieldStyle(object, '@text', 'inputs', 'color'),
      accentColor: getFormFieldStyle(
        object,
        '@primary',
        'inputs',
        'accentColor'
      ),
      backgroundColor: getFormFieldStyle(
        object,
        '#fff',
        'inputs',
        'backgroundColor'
      ),
      borderColor: getFormFieldStyle(object, '#ccc', 'inputs', 'borderColor'),
      borderRadius: getFormFieldStyle(object, 4, 'inputs', 'borderRadius'),
      borderStyle: getFormFieldStyle(
        object,
        borderStyles.SOLID,
        'inputs',
        'borderStyle'
      ),
      borderWidth: getFormFieldStyle(object, 1, 'inputs', 'borderWidth'),
      fontWeight: getFormFieldStyle(object, '400', 'inputs', 'fontWeight'),
      fontFamily: getFormFieldStyle(object, '@body', 'inputs', 'fontFamily'),
      placeholderColor: getFormFieldStyle(
        object,
        '#aaa',
        'inputs',
        'placeholderColor'
      ),
      shadow: getFormFieldStyle(object, { enabled: false }, 'inputs', 'shadow'),
    }

    const otherProps = {}

    let height =
      fieldType === dataTypes.TEXT && multiline
        ? 1.3 * 2 * inputFontSize + 2 * inputPadding
        : 1.3 * inputFontSize + 2 * inputPadding

    if (fieldType === dataTypes.BOOLEAN) {
      width = 16
      height = 16
      layerType = SECTION
      y += 1
    }

    if (fieldType === dataTypes.DATE) {
      layerType = DATE_PICKER
    } else if (fieldType === dataTypes.DATE_ONLY) {
      const { datePickerStyle } = this.props

      layerType = DATE_PICKER
      otherProps.datePickerStyle = datePickerStyle || dataTypes.DATE_ONLY
    } else if (fieldType === dataTypes.IMAGE) {
      layerType = IMAGE_UPLOAD
      otherProps.imageUploadType = 'fullWidth'

      otherProps.color = normalizeColor(
        getFormFieldStyle(object, '@primary', 'inputs', 'accentColor'),
        branding
      )

      otherProps.backgroundColor = inputStyles.backgroundColor
      otherProps.borderRadius = inputStyles.borderRadius
      otherProps.borderColor = inputStyles.borderColor
      otherProps.borderWidth = inputStyles.borderWidth

      height = 212
    } else if (fieldType === dataTypes.FILE) {
      layerType = FILE_UPLOAD

      otherProps.color = normalizeColor(
        getFormFieldStyle(object, '@primary', 'inputs', 'accentColor'),
        branding
      )

      height = 80
    } else if (fieldType.type === 'belongsTo') {
      layerType = SELECT
    } else if (fieldType === dataTypes.LOCATION) {
      otherProps.icon = icon
    }

    if (editorResizingProps) {
      otherProps.editorResizingProps = { ...editorResizingProps, relativeX: 0 }
    }

    return {
      multiline,
      placeholder,
      height,
      x: 0,
      y,
      backgroundStyle: backgroundStyles.COLOR,
      borderPosition: borderPosition.INSIDE,
      fontSize: inputFontSize,
      padding: inputPadding,
      ...inputStyles,
      ...otherProps,
      id: `${object.id}-${index}-1`,
      type: layerType,
      zoom,
      width,
    }
  }

  getLabelProps = () => {
    let { label, width, index, zoom, layout, object, type } = this.props
    let x = 0

    if (type === dataTypes.BOOLEAN) {
      x = 24
      width -= 24
    }

    return {
      id: `${index}-0`,
      zoom,
      width,
      height: 20,
      x,
      y: layout.label,
      color: '@text',
      text: label,
      layoutText: [label],
      fontSize: 14,
      fontWeight: '400',
      fontFamily: getFormFieldStyle(object, '@body', 'labels', 'fontFamily'),
      type: LABEL,
      ...getFormFieldStyle(object, null, 'labels'),
    }
  }

  render() {
    const { label, zoom, branding } = this.props

    return (
      <g>
        {label ? (
          <CanvasObject
            zoom={zoom}
            branding={branding}
            object={this.getLabelProps()}
          />
        ) : null}
        <CanvasObject
          zoom={zoom}
          branding={branding}
          object={this.getInputProps()}
        />
      </g>
    )
  }
}

const mapStateToProps = state => {
  return {
    branding: getAppBranding(state, getCurrentAppId(state)),
  }
}

export default connect(mapStateToProps)(Form)
