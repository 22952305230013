import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form'
import { EVENT_OPTIONS } from '@adalo/constants'

import WrappedSelect from 'components/Shared/Forms/WrappedSelect'
import { IconButton } from 'components/Shared/Icon'

import ActionList from './ActionList'

import './Form.scss'

class ActionForm extends Component {
  handleSortEnd = ({ oldIndex, newIndex }) => {
    let { actions, change } = this.props
    const actionItem = actions[oldIndex]
    let firstLinkActionItemIndex

    for (const item in actions) {
      if (
        actions[item].actionType === 'navigate' ||
        actions[item].actionType === 'externalLink'
      ) {
        firstLinkActionItemIndex = item

        break
      }
    }

    if (
      actionItem.actionType !== 'navigate' &&
      actionItem.actionType !== 'externalLink'
    ) {
      if (newIndex >= firstLinkActionItemIndex) {
        newIndex = firstLinkActionItemIndex - 1
      }
    } else if (newIndex <= firstLinkActionItemIndex) {
      newIndex = firstLinkActionItemIndex
    }

    actions = actions.slice()
    actions.splice(oldIndex, 1)
    actions.splice(newIndex, 0, actionItem)
    change('actions', actions)
  }

  render() {
    const {
      appId,
      componentId,
      objectId,
      actions,
      onRemove,
      reference,
      form,
      dummyActions,
    } = this.props

    return (
      <div className="action-item-form">
        <div className="action-item-form-row action-item-header">
          <label>Trigger</label>
          <Field
            className="small"
            component={WrappedSelect}
            name="eventType"
            placeholder="Select trigger..."
            options={EVENT_OPTIONS}
          />
          <IconButton type="trash-small" onClick={onRemove} />
        </div>
        {actions ? (
          <div className="action-actions-list">
            <FieldArray
              appId={appId}
              dummyActions={dummyActions}
              componentId={componentId}
              objectId={objectId}
              actions={actions}
              reference={reference}
              name="actions"
              component={ActionList}
              distance={5}
              onSortEnd={this.handleSortEnd}
              form={form}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state, { form }) => {
  const selector = formValueSelector(form)

  return {
    actions: selector(state, 'actions'),
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    // Add some config
  })(ActionForm)
)
