import {
  COMPONENT,
  GROUP,
  LABEL,
  SHAPE,
  INPUT,
  LIST,
  SECTION,
  ELLIPSE,
  IMAGE,
  LIBRARY_COMPONENT,
  responsivePositioningOptions,
  DeviceType,
  DeviceWidth,
  DeviceBreakpoint,
  LAYOUT_SECTION,
} from '@adalo/constants'

import {
  insert,
  update,
  remove,
  evaluate,
  getBoundingBox,
  getDropPath,
  getGroupPath,
  getInsertPath,
  getObject,
  incrementPath,
  decrementPath,
  isChildPath,
  joinPaths,
  remapSiblings,
  removeChildren,
  subPath,
  pathLength,
  nextPath,
  translateChildren,
  mergeReducers,
  getId,
  sortPaths,
  deepMerge,
  getDeviceType,
  getSiblings,
} from '@adalo/utils'
import { isEmpty } from 'lodash'
import { createLayoutSection } from 'utils/layoutSections/modifiers'
import { setGlobalDefaults } from 'utils/library-globals'
import {
  getSectionFromChild,
  getSectionFromSectionElement,
  isSectionElement,
  shouldReparentComponent,
} from 'utils/layoutSections'
import { convertType, indexByType } from 'utils/objects'
import createEmptyObject from 'ducks/editor/objects/helpers/createEmptyObject'

import { getDefaultListResponsivity } from 'utils/defaultConstraints'

import { updateComponentBounds } from 'utils/libraries'
import { saveTouched, saveDiffed, setPrevList } from 'utils/saving'
import { requestApp, saveLibraryGlobals } from 'utils/io'
import { updateObjectWidth } from 'utils/text'
import { getSnapGrid, getSnapGridForParent } from 'utils/snapping'
import { calculateZoom, getOffset } from 'utils/zoom'
import { updateOptions, updateParentOptions } from 'utils/groupTypes'
import { convertToInput } from 'utils/conversion'
import updateParentBounds from 'utils/operations/updateParentBounds'
import updateBounds from 'utils/operations/updateBounds'
import resizeParent from 'utils/operations/shouldResizeParent'

import {
  getBestParent,
  getInsertPosition,
  getAbsoluteBbox,
  alignToRect,
} from 'utils/geometry'
import { defaultListChildren, responsiveDefaultListChildren } from 'utils/lists'

import getParentScreen from 'ducks/editor/objects/helpers/getParentScreen'
import { getFeatureFlag } from 'ducks/featureFlags'
import getDeviceObject from 'utils/getDeviceObject'
import inferFixedPositionAnchor from 'utils/objects/inferFixedPositionAnchor'
import { getWidthConstraints } from 'utils/objects/widthConstraints'
import { evaluateUserflowEvents, USERFLOW_EVENTS } from 'utils/userflow'

import { getAccordionState, LIBRARY_INSPECT_GROUP } from 'ducks/accordions'

import { getApp } from 'ducks/apps'
import selectionReducer, {
  SELECT_PARENT,
  getActiveComponent,
} from '../selection'

import { SET_TOOL } from '../tools'

import textEditingReducer from '../textEditing'
import shapeEditingReducer from '../shapeEditing'
import sketchUploadReducer from '../sketchUpload'
import positioningReducer from '../positioning'
import clipboardReducer from '../clipboard'
import snappingReducer, { snappingMiddleware } from '../snapping'

import {
  applyInstructions,
  disableDeviceSpecificLayout,
  enableDeviceSpecificLayout,
  // TODO(michael-adalo): temporarily disabling due to multiple issues related to this instruction
  // and our bounds calculations/handling
  // fitScreenHeightToComponents,
  getTouchedFromInstruction,
  moveElement,
  moveScreen,
  resizeElement,
  resizeScreen,
  updateElement,
  updateElementMargins,
  snapToBottom,
} from '../instructions'
import {
  applyLayoutInstruction,
  getTouchedFromInstruction as getSelectionTouchedFromInstruction,
} from '../selection-instructions'

import calculatePushGraphs from '../pushing/calculatePushGraphs'
import coerceScreenResizeToMinimums from '../positioning/coerceScreenResizeToMinimums'
import getContainingScreen from './helpers/getContainingScreen'
import isParentVisible from './helpers/isParentVisible'
import { getParent } from '../device-layouts/utils'

import {
  REQUEST_DATA,
  SET_DATA,
  CREATE_OBJECT,
  UPDATE_OBJECT,
  UPDATE_OBJECTS,
  CHANGE_OBJECT_TYPE,
  RESIZE_OBJECT,
  POSITION_OBJECTS,
  DELETE_OBJECT,
  REORDER_OBJECTS,
  REORDER_OBJECTS_MOVE_LAST,
  REORDER_OBJECTS_MOVE_FIRST,
  REORDER_OBJECTS_MOVE_UP,
  REORDER_OBJECTS_MOVE_DOWN,
  GROUP_OBJECTS,
  GROUP_OBJECTS_TO_LIST,
  UNGROUP_OBJECTS,
  ALIGN_OBJECTS,
  ZOOM,
  RESET_ZOOM,
  PAN,
  SET_LIBRARY_GLOBAL,
  RUN_INSTRUCTIONS,
  RUN_SELECTION_INSTRUCTION,
} from './actions'
import { getSelectedSub } from './selectors'

export * from './actions'
export * from './selectors'

const { CENTER } = responsivePositioningOptions

const DISABLED_ACTIONS_FOR_SECTION_ELEMENTS = new Set([
  REORDER_OBJECTS_MOVE_FIRST,
  REORDER_OBJECTS_MOVE_LAST,
  REORDER_OBJECTS_MOVE_UP,
  REORDER_OBJECTS_MOVE_DOWN,
])

export const INITIAL_STATE = {
  appId: null,
  loading: false,
  name: null,
  zoom: {
    scale: 1,
    offset: [0, 0],
  },
  map: {},
  parentMap: {},
  list: [],

  // Indices map to components at the top level
  // typeIndex: { COMPONENT1: { TYPE: [...] } }
  // Others: { COMPONENT1: [...] }
  typeIndex: {},
  selection: [],
  hoverSelection: [],
  activeComponent: null,
  textEditing: null,
  shapeEditing: null,
  draggingOutControl: false,
  draggingInControl: false,
  selectedPoint: null,
  draggingSelectedPoint: false,
  zoomAppId: null,
  dragging: false,
  panning: false,
  xGrid: null,
  yGrid: null,
  currentXSnap: null,
  currentYSnap: null,
  positioningObjects: null,
  positioningStartPoint: null,
  positioningConstraint: null,
  sketchUpload: {
    uploadInProgress: false,
    uploadError: null,
    progress: null,
  },
  selectedParent: null,
  libraryGlobals: {},
}

const getComponentObject = (components, id) => {
  const componentObj = components[id]

  const {
    x,
    y,
    width,
    height,
    objects,
    pushGraph,
    mobile,
    tablet,
    desktop,
    name,
    backgroundColor,
    backgroundImage,
    backgroundPositionX,
    backgroundPositionY,
    backgroundSize,
    statusBarStyle,
    reverseScroll,
    reusableComponent,
    onVisit,
    componentActions,
    screenTemplate,
  } = componentObj

  return {
    id,
    name,
    backgroundColor,
    backgroundImage,
    backgroundPositionX,
    backgroundPositionY,
    backgroundSize,
    onVisit,
    componentActions,
    type: COMPONENT,
    x: x || 0,
    y: y || 0,
    width,
    height,
    reusableComponent,
    statusBarStyle,
    reverseScroll,
    screenTemplate,
    children: objects,
    pushGraph,
    mobile,
    tablet,
    desktop,
  }
}

export const getParentId = (
  list,
  map,
  typeIndex,
  parentTypes,
  newObject,
  mouseCoords,
  zoom
) => {
  const { magicLayout } = newObject
  const containerIds = []

  const potentialParentFilter = listId => {
    if (listId === newObject.id) {
      return false
    }

    if (
      map[newObject.id] &&
      isChildPath(map[newObject.id] || '', map[listId])
    ) {
      return false
    }

    return true
  }

  // This needs to iterate over all screens in case the object was dragged from one screen to another.
  // The performance is passable in most cases due to the typeindex.
  for (const component of list) {
    const screenElementsByType = typeIndex[component.id]
    if (!screenElementsByType) {
      continue
    }

    for (const parentType of parentTypes) {
      let listIds = screenElementsByType[parentType] || []

      if (magicLayout) {
        const device = getDeviceType(component.width)

        const removeHidden = id => {
          const containerObject = getObject(list, map[id])

          if (
            !containerObject ||
            (containerObject.deviceVisibility &&
              !containerObject.deviceVisibility[device])
          ) {
            return false
          }

          return isParentVisible(containerObject, device, list, map)
        }

        listIds = listIds.filter(removeHidden)
      }

      containerIds.push(...listIds.filter(potentialParentFilter))
    }
  }

  const canvasRelativeBBoxes = containerIds.map(listId => {
    let device

    if (magicLayout) {
      const screen = getParentScreen(list, map, listId)
      device = screen ? getDeviceType(screen.width) : undefined
    }

    return getAbsoluteBbox(getObject(list, map[listId]), list, map, device)
  })

  let currentParent = null

  if (map && map[newObject.id]) {
    currentParent = getParent(list, map, newObject.id)
  }

  return getBestParent(
    newObject,
    list.concat(...canvasRelativeBBoxes),
    map,
    currentParent,
    zoom,
    mouseCoords,
    list
  )
}

const updatePositioning = (list, pathMap, objectId, mouseCoords = {}) => {
  const obj = getObject(list, pathMap[objectId])

  const { y, height } = obj
  const { height: screenHeight } = getContainingScreen(list, pathMap, objectId)

  const positioning = inferFixedPositionAnchor(y, height, screenHeight)

  return update(list, pathMap[objectId], {
    ...obj,
    positioning,
  })
}

const innerPerformCreate = (
  object,
  list,
  objects,
  id,
  newIds,
  state,
  parentId,
  map,
  path,
  isCopy,
  isRelative,
  setSelection,
  zoom,
  selection,
  objectIds,
  newObjects,
  changeIds = true,
  includeInitialDevice = false,
  mobileOnly = false,
  mouseCoords = {}
) => {
  let newObject = createEmptyObject(
    list,
    map,
    object,
    changeIds,
    mobileOnly,
    true
  )

  if (objects.length === 1 && id) {
    newObject.id = id
  }

  newIds.push(newObject.id)

  if (!parentId) {
    const parentTypes = [LIST]
    if (state && state.magicLayout) {
      parentTypes.push(SECTION, IMAGE, ELLIPSE, GROUP)
    }

    parentId = getParentId(
      list,
      map,
      state.typeIndex,
      parentTypes,
      {
        ...newObject,
        magicLayout: state && state.magicLayout,
      },
      mouseCoords,
      zoom
    )

    const component = getObject(list, subPath(map[parentId], 1))
    const parentPath = map[parentId]
    const parent = parentPath ? getObject(list, parentPath) : undefined

    if (!shouldReparentComponent(object, parent)) {
      parentId = component.id
    }
  }

  let newPath = getInsertPath(list, path, map[parentId])

  if (isCopy && path) {
    newPath = path
    path = nextPath(path)
  }

  if (newObject.type === COMPONENT && pathLength(newPath) > 1) {
    newPath = nextPath(subPath(newPath, 1))
  }

  if (parentId) {
    const component = getObject(list, subPath(map[parentId], 1))
    const device = getDeviceType(component.width)

    if (!isRelative) {
      newObject.x -= component.x
      newObject.y -= component.y
    }

    // Right now we should only set the initial device when the hasAutoCustomLayout flag is set
    // disabling initialDevice on test env so tests work as pre- auto custom layouts, until we update the tests and add tests for this feature

    if (includeInitialDevice && process.env.NODE_ENV !== 'test') {
      newObject.initialDevice = device
      if ((newObject.children?.length ?? 0) > 0) {
        newObject.children.forEach(child => {
          child.initialDevice = device
        })
      }
    }
  } else {
    // Get out of here if trying to insert non-component at top-level
    if (newObject.type !== COMPONENT) {
      return null
    }

    if (
      !isRelative &&
      !(newObject.x !== undefined || newObject.y !== undefined)
    ) {
      const position = getInsertPosition(list)
      newObject = { ...newObject, ...position }
    }

    if (setSelection) {
      zoom = calculateZoom(newObject)
    }
  }

  newObject = updateObjectWidth(newObject)

  const magicLayout = state && state.magicLayout

  list = insert(list, newPath, newObject)
  map = remapSiblings(list, map, newPath)
  list = updateParentBounds(
    list,
    map,
    newObject.id,
    null,
    resizeParent,
    null,
    magicLayout
  )
  list = updateOptions(list, map, newObject.id)
  if (state && state.magicLayout) {
    list = updatePositioning(list, map, newObject.id, mouseCoords)
  }

  if (newObject.type !== COMPONENT) {
    selection.push(newObject.id)
  }

  if (
    newObject.type === COMPONENT &&
    newObject.objects &&
    newObject.objects.length > 0
  ) {
    const children = newObject.objects
    newObject.objects = []

    children.forEach(child => {
      const result = innerPerformCreate(
        child,
        list,
        [],
        null,
        newIds,
        state,
        newObject.id,
        map,
        path,
        isCopy,
        true,
        setSelection,
        zoom,
        [],
        objectIds,
        newObjects,
        changeIds,
        includeInitialDevice,
        mobileOnly,
        mouseCoords
      )

      ;({ list, map } = result)
    })
  }

  if (
    newObject.type === LIST &&
    !isCopy &&
    (!newObject.children || newObject.children.length === 0)
  ) {
    const defaultChildren = state.magicLayout
      ? responsiveDefaultListChildren
      : defaultListChildren

    const defaultChildResponsivity = getDefaultListResponsivity()
    if (mobileOnly) {
      defaultChildResponsivity.horizontalPositioning = CENTER
    }

    defaultChildren.forEach(child => {
      child = {
        ...child,
        x: newObject.x + child.x,
        y: newObject.y + child.y,
        id: getId(),
        responsivity: defaultChildResponsivity,
      }

      if (state.magicLayout && child.children) {
        child.children = child.children.map(item => ({
          ...item,
          x: item.x + child.x,
          y: item.y + child.y,
          id: getId(),
          responsivity: defaultChildResponsivity,
        }))
      }

      const result = innerPerformCreate(
        child,
        list,
        [],
        null,
        newIds,
        state,
        newObject.id,
        map,
        path,
        isCopy,
        true,
        setSelection,
        zoom,
        [],
        objectIds,
        newObjects,
        changeIds,
        includeInitialDevice,
        mobileOnly,
        mouseCoords
      )

      ;({ list, map } = result)
    })
  }

  objectIds.push(newObject.id)
  newObjects.push(newObject)

  return { list, parentId, map, path, zoom }
}

export const performCreate = (
  state,
  path,
  objects,
  id,
  parentId,
  isRelative = false,
  isCopy = false,
  setSelection = true,
  changeIds = true,
  includeInitialDevice = false,
  mobileOnly = false,
  mouseCoords = {},
  newScreenWidth = null
) => {
  let { list, map, zoom, magicLayout } = state
  const objectIds = []
  const selection = []
  const newObjects = []
  const newIds = []

  if (!Array.isArray(objects)) {
    objects = [objects]
  }

  if (!parentId && state.selectedParent) {
    parentId = state.selectedParent
  }

  state = {
    ...state,
    selectedParent: null,
  }

  const componentIds = []
  for (const object of objects) {
    const result = innerPerformCreate(
      object,
      list,
      objects,
      id,
      newIds,
      state,
      parentId,
      map,
      path,
      isCopy,
      isRelative,
      setSelection,
      zoom,
      selection,
      objectIds,
      newObjects,
      changeIds,
      includeInitialDevice,
      mobileOnly,
      mouseCoords
    )

    if (result == null) {
      // Get out of here if trying to insert non-component at top-level
      return state
    }

    ;({ list, map, parentId, path, zoom } = result)

    const { type } = object

    if (type === COMPONENT) {
      if (isCopy) {
        const newScreenId =
          newIds.length > 0 ? newIds[newIds.length - 1] : object.id
        if (newScreenId) {
          componentIds.push(newScreenId)
        }
      } else {
        // screen templates
        componentIds.push(id)
      }
    }

    if (type !== COMPONENT && parentId !== null) {
      const { id: componentId } = getObject(list, subPath(map[parentId], 1))

      if (!componentIds.includes(componentId)) {
        componentIds.push(componentId)
      }
    }
  }

  if (magicLayout) {
    // Runs when adding a new component directly into a container type either by:
    // - dragging from the Add Component panel
    // - using hotkeys and clicking into the container type
    const newParentObjectIds = []

    if (isCopy === false && parentId && newObjects.length > 0) {
      for (const object of newObjects) {
        if (object.type === COMPONENT) {
          continue
        }

        const parent = getParent(list, map, object.id)

        if (parent.id !== parentId) {
          continue
        }

        newParentObjectIds.push(object.id)
      }
    }

    for (const componentId of componentIds) {
      const component = getObject(list, map[componentId])

      // TODO (michael-adalo): re-consider this logic when turning Create Object into an Instruction
      const device = getDeviceType(component.width)

      if (component.isTemplate && component.isTemplate === true) {
        delete component.objects
        list = update(list, map[componentId], component)
      }

      list = calculatePushGraphs(list, map, componentId)

      // we should not reset any device specific layout config
      // if the parent component is the actual screen
      const isParentScreen = parentId === component?.id

      for (const objectId of newParentObjectIds) {
        const { x, y, width, height } = getDeviceObject(
          getObject(list, map[objectId]),
          device
        )

        const instructions = []

        if (!isParentScreen) {
          instructions.push(
            ...[DeviceType.DESKTOP, DeviceType.TABLET, DeviceType.MOBILE].map(
              deviceType => disableDeviceSpecificLayout(objectId, deviceType)
            )
          )
        }

        instructions.push(moveElement(objectId, x, y))
        instructions.push(resizeElement(objectId, width, height))
        ;({ list } = applyInstructions({ list, pathMap: map }, instructions))
      }
    }

    if (objects[0] && objects[0].type === COMPONENT && newScreenWidth) {
      const instructions = [
        resizeScreen(componentIds[0], newScreenWidth, objects[0].height),
      ]
      ;({ list } = applyInstructions({ list, pathMap: map }, instructions))
    }
  }

  const libraryGlobals = setGlobalDefaults(state.libraryGlobals, newObjects)

  saveTouched(
    state.appId,
    list,
    map,
    newObjects.map(o => o.id),
    undefined,
    undefined,
    libraryGlobals
  )

  const newObject = newObjects.length === 1 ? newObjects[0] : {}

  if (newObject.type === LAYOUT_SECTION) {
    // Create new layout section adapted for screen
    ;({ list, pathMap: map } = createLayoutSection(
      list,
      map,
      parentId,
      newObject
    ))

    // If snapping below a section, make sure it's right below on all devices
    const screen = getParentScreen(list, map, parentId)
    const device = getDeviceType(screen.width)
    const layoutSection = getObject(list, map[newObject.id])

    const top = objects[0].y
    const { yGrid } = getSnapGridForParent(state, state.selection, screen.id)
    const { objects: snapObjects = [] } =
      yGrid.find(({ edge, point }) => edge && point === top) || {}

    if (snapObjects.length > 0) {
      const aboveSection = snapObjects.find(
        ({ type }) => type === LAYOUT_SECTION
      )

      if (aboveSection) {
        const instructions = [snapToBottom(newObject.id, aboveSection.id)]
        ;({ list } = applyInstructions({ list, pathMap: map }, instructions))
      }
    }

    const layoutSectionDevice = getDeviceObject(layoutSection, device)
    const deviceScreen = getDeviceObject(screen, device)

    const instructions = [
      resizeScreen(
        screen.id,
        screen.width,
        Math.max(
          deviceScreen.height,
          layoutSectionDevice.height + layoutSectionDevice.y
        )
      ),
    ]

    ;({ list } = applyInstructions({ list, pathMap: map }, instructions))
  }

  // in case we have newly created screens
  // (such as one that was pasted into the app or simply created in the editor from the templates)
  // we need to check if when in "mobile only" mode, if the screen added
  // exceeds the width limit for mobile devices
  for (const componentId of componentIds) {
    const component = getObject(list, map[componentId])
    const device = getDeviceType(component.width)

    if (mobileOnly && component.width >= DeviceBreakpoint.MOBILE_BREAKPOINT) {
      const instructions = []

      const deviceComponent = getDeviceObject(component, device)

      instructions.push(
        resizeScreen(
          component?.id,
          DeviceWidth.MOBILE_DEFAULT_WIDTH,
          deviceComponent.height
        )
      )
      ;({ list } = applyInstructions({ list, pathMap: map }, instructions))
    }

    saveTouched(
      state.appId,
      list,
      map,
      newObjects.map(o => o.id),
      undefined,
      undefined,
      libraryGlobals
    )
  }

  const result = [
    updateIndices({
      ...state,
      list,
      map,
      zoom,
      libraryGlobals,
      selection: setSelection ? selection : state.selection,
      activeComponent: getActiveComponent(list, map, selection),
      shapeEditing: newObject.type === SHAPE && newObject.id,
    }),
    ...newIds,
  ]

  return result
}

const isPropChanging = (propKey, oldObject, changes) => {
  if (!propKey || !oldObject || !changes) {
    return false
  }

  const propGettingSet = propKey in changes && changes[propKey] !== undefined
  const propValueDifferent = oldObject[propKey] !== changes[propKey]

  return propGettingSet && propValueDifferent
}

/**
 * @typedef PositionSizeChange
 * @property {number} [x]
 * @property {number} [y]
 * @property {number} [width]
 * @property {number} [height]
 */

/**
 *
 * @param {import('utils/responsiveTypes').EditorObject} oldDeviceObject
 * @param {PositionSizeChange} changes
 * @returns {import('../instructions/applyInstructions').Instruction[]}
 */
const inferInstructions = (oldDeviceObject, changes) => {
  const { id: objectId, type } = oldDeviceObject

  const actualChanges = {}

  for (const propKey in changes) {
    if (isPropChanging(propKey)) {
      actualChanges[propKey] = changes[propKey]
    }
  }

  changes = coerceScreenResizeToMinimums(oldDeviceObject, changes)

  const result = []
  if ('x' in changes || 'y' in changes) {
    const { x = oldDeviceObject.x, y = oldDeviceObject.y } = changes

    if (type === COMPONENT) {
      result.push(moveScreen(objectId, x, y))
    } else {
      result.push(updateElementMargins(objectId), moveElement(objectId, x, y))
    }
  }

  if ('width' in changes || 'height' in changes) {
    const { width = oldDeviceObject.width, height = oldDeviceObject.height } = changes // prettier-ignore

    if (type === COMPONENT) {
      if ('width' in changes && 'height' in changes === false) {
        result.push(resizeScreen(objectId, width))
      } else {
        result.push(resizeScreen(objectId, width, height))
      }
    } else {
      const { constrainMinWidth, constrainMaxWidth } = getWidthConstraints(
        oldDeviceObject,
        width
      )

      const { y = oldDeviceObject.y } = changes

      if (constrainMinWidth === true) {
        // NOTE: early-out!
        // disregard any incoming move changes to prevent drift
        // by forcing the width to the min width and position to existing position
        return [
          resizeElement(objectId, oldDeviceObject.minWidth, height),
          moveElement(objectId, oldDeviceObject.x, y),
        ]
      } else if (constrainMaxWidth === true) {
        // NOTE: early-out!
        // disregard any incoming move changes to prevent drift
        // by forcing the width to the max width and position to existing position
        return [
          resizeElement(objectId, oldDeviceObject.maxWidth, height),
          moveElement(objectId, oldDeviceObject.x, y),
        ]
      } else {
        result.push(resizeElement(objectId, width, height))
      }
    }
  }

  return result
}

/**
 *
 * @param {object} state
 * @param {string | string[]} id
 * @returns {typeof INITIAL_STATE}
 */
export const performDelete = (state, id) => {
  let { list, map } = state

  const object = getObject(state.list, state.map[id])

  if (isSectionElement(object)) {
    const parentSection = getSectionFromSectionElement(
      state.list,
      state.map,
      object
    )

    return performDelete(state, parentSection.id)
  }

  if (!Array.isArray(id)) {
    id = [id]
  }
  const { magicLayout } = state

  const paths = []
  const componentIds = []

  id.forEach(id => {
    const path = map[id]
    const obj = getObject(list, path)

    if (obj.type === COMPONENT) {
      componentIds.push(id)
    } else {
      paths.push(path)
    }

    list = remove(list, path)

    list = updateParentBounds(
      list,
      map,
      id,
      null,
      resizeParent,
      undefined,
      magicLayout
    )
    list = updateParentOptions(list, map, id)

    map = { ...map }
    delete map[id]
    map = remapChildren(object, map, path)
    map = remapSiblings(list, map, subPath(path, path.length - 1))

    const pieces = path.split('.')
    const parentPath = pieces.slice(0, pieces.length - 1).join('.')
    let siblings = []

    if (parentPath === '') {
      siblings = list
    } else {
      const parentObj = getObject(list, parentPath)

      siblings = (parentObj && parentObj.children) || []
    }

    const pathPrefix = parentPath === '' ? '' : `${parentPath}.`

    siblings.forEach((sibling, position) => {
      map[sibling.id] = `${pathPrefix}${position}`
    })
  })

  const changedScreenIds = []
  paths.forEach(path => {
    const parentScreen = getObject(list, subPath(path, 1))

    if (
      !componentIds.includes(parentScreen) &&
      !changedScreenIds.includes(parentScreen.id)
    ) {
      changedScreenIds.push(parentScreen?.id)
    }
  })

  if (magicLayout) {
    for (const componentId of changedScreenIds) {
      list = calculatePushGraphs(list, map, componentId)
    }
  }

  saveTouched(state.appId, list, map, null, paths, componentIds)

  return updateIndices({
    ...state,
    list,
    map,
    selection: [],
    hoverSelection: [],
    shapeEditing: null,
  })
}

const remapChildren = (object, map, path) => {
  if (!Array.isArray(object?.children) || object?.children?.length === 0) {
    return map
  }

  let newMap = { ...map }

  for (const child of object.children) {
    const childPath = map[child.id]

    if (childPath.startsWith(path)) {
      delete newMap[child.id]
    }

    if (child.children) {
      newMap = remapChildren(child, newMap, childPath)
    }
  }

  return newMap
}

const updateIndices = state => {
  const componentPaths = Object.keys(state.list)

  const typeIndex = { ...state.typeIndex }

  componentPaths.forEach(path => {
    const component = state.list[path]

    typeIndex[component.id] = indexByType(component.children)
  })

  return {
    ...state,
    typeIndex,
  }
}

const addMissingPushGraphsToScreens = (list, map) => {
  const screensWithoutPushGraphs = list
    .filter(({ type, pushGraph }) => type === COMPONENT && !pushGraph)
    .map(({ id }) => id)

  for (const screenId of screensWithoutPushGraphs) {
    list = calculatePushGraphs(list, map, screenId)
  }

  return list
}

// REDUCER

export default mergeReducers(
  INITIAL_STATE,
  [snappingMiddleware],
  selectionReducer,
  textEditingReducer,
  shapeEditingReducer,
  sketchUploadReducer,
  positioningReducer,
  clipboardReducer,
  snappingReducer,

  (state, action) => {
    if (DISABLED_ACTIONS_FOR_SECTION_ELEMENTS.has(action.type) && action.id) {
      const object = getObject(state.list, state.map[action.id])

      if (object && isSectionElement(object)) {
        return state
      }
    }

    if (action.type === REQUEST_DATA) {
      const { appId } = action

      // Make call to API
      requestApp(appId)

      if (appId === state.appId) {
        return { ...state, loading: true }
      }

      setPrevList(null)

      return {
        ...INITIAL_STATE,
        appId,
        loading: true,
      }
    }

    if (action.type === SET_DATA) {
      const { appId, data, app } = action

      let list = Object.keys(data || {}).map(id => getComponentObject(data, id))

      const libraryGlobals = app.libraryGlobals || {}
      const map = remapSiblings(list, {}, '0')

      const { magicLayout = false } = app || {}

      // Initialize push graphs
      if (magicLayout) {
        list = addMissingPushGraphsToScreens(list, map)
      }

      let zoom = state.zoom

      if (state.zoomAppId !== appId) {
        // focus on launch component
        let launchComponent = list.find(
          ({ id }) => app.launchComponentId === id
        )

        if (!launchComponent && list.length > 0) {
          launchComponent = list[0]
        }

        if (launchComponent) {
          let { x, y, width, height } = launchComponent
          const padding = 400
          x = x - padding
          y = y - padding
          width = width + 2 * padding
          height = height + 2 * padding

          zoom = calculateZoom({ x, y, width, height })
        } else {
          zoom = calculateZoom(null)
        }
      }

      setPrevList(list)

      let newState = updateIndices({
        ...state,
        appId,
        list,
        map,
        zoom,
        magicLayout,
        libraryGlobals,
        loading: false,
      })

      newState = {
        ...newState,
        ...getSnapGrid(newState, []),
      }

      return newState
    }

    if (action.type === CREATE_OBJECT) {
      const { path, object, id, silent, mouseCoords, globalState, parentId } =
        action

      const app = getApp(globalState, state.appId)
      const mobileOnly = app?.webSettings?.layoutMode === 'mobile'

      let newScreenWidth = null
      // If creating new screen on responsive, we want the new screen to match the home screen
      if (
        object?.type === COMPONENT &&
        app &&
        app.primaryPlatform === 'responsive'
      ) {
        const launchComponentId = app?.launchComponentId
        const homeScreen = app.components[launchComponentId]
        const deviceTarget = getDeviceType(homeScreen?.width)

        newScreenWidth = DeviceWidth.MOBILE_DEFAULT_WIDTH

        if (deviceTarget === DeviceType.DESKTOP) {
          newScreenWidth = DeviceWidth.DESKTOP_DEFAULT_WIDTH
        } else if (deviceTarget === DeviceType.TABLET) {
          newScreenWidth = DeviceWidth.TABLET_DEFAULT_WIDTH
        }
      }

      return performCreate(
        state,
        path,
        object,
        id,
        parentId ?? null,
        false,
        false,
        !silent,
        undefined,
        true,
        mobileOnly,
        mouseCoords,
        newScreenWidth
      )[0]
    }

    if (action.type === UPDATE_OBJECT || action.type === RESIZE_OBJECT) {
      const { id, object, skipSave, globalState, resetAccordion } = action

      const path = state.map[id]
      if (!path) {
        return state
      }

      const { magicLayout, libraryGlobals } = state

      /** @type {import('utils/responsiveTypes').EditorObject} */
      const oldObject = getObject(state.list, path) || {}
      /** @type {Partial<import('utils/responsiveTypes').EditorObject} */
      const changes = evaluate(object, oldObject)

      evaluateUserflowEvents(
        [USERFLOW_EVENTS.LIST_CONNECTED, USERFLOW_EVENTS.LINK_ACTION_CREATED],
        state.appId,
        {
          object,
          oldObject,
        }
      ).catch(console.error)

      /** @type {import('../types/ObjectList').ObjectList} */
      let list = state.list

      /** @type {import('utils/responsiveTypes').EditorObject} */
      const screen = getObject(list, subPath(path, 1))

      // handle RESPONSIVE updates
      if (magicLayout === true) {
        const device = getDeviceType(screen.width)
        const oldDeviceObject = getDeviceObject(oldObject, device)

        /** @type {import('../instructions/applyInstructions').Instruction[]} */
        const instructions = []

        const positionInstructions = inferInstructions(oldDeviceObject, changes)
        const hasLayoutInstruction = positionInstructions.some(i =>
          ['moveElement', 'resizeElement'].includes(i.operation)
        )

        if (
          !!getFeatureFlag(globalState, 'hasAutoCustomLayout') &&
          hasLayoutInstruction &&
          oldObject.type !== COMPONENT &&
          oldDeviceObject.initialDevice !== device
        ) {
          // With Auto Custom Layout on, before a layout change
          // in a device different from the initial one happens,
          // we enable device-specific layout in that device
          instructions.push(enableDeviceSpecificLayout(oldObject.id, device))
        }

        const { width, height, x, y, ...otherChanges } = changes

        if (!isEmpty(otherChanges) && action.type === UPDATE_OBJECT) {
          instructions.push(updateElement(oldObject.id, otherChanges))
        }

        if (positionInstructions.length > 0) {
          instructions.push(...positionInstructions)
        }

        if (oldObject.type !== COMPONENT) {
          instructions.push(resizeScreen(screen.id, screen.width))
        }

        // in case we have newly created screens
        // (such as one that was pasted into the app or simply created in the editor from the templates)
        // we need to check if when in "mobile only" mode, if the screen added
        // exceeds the width limit for mobile devices
        const hasNewMobileOnlyApp = getFeatureFlag(globalState, 'hasNewMobileOnlyApp') // prettier-ignore
        const app = getApp(globalState, state.appId)
        const mobileOnly = hasNewMobileOnlyApp && app?.webSettings?.layoutMode === 'mobile' // prettier-ignore

        if (mobileOnly && screen.width >= DeviceBreakpoint.MOBILE_BREAKPOINT) {
          instructions.push(resizeScreen(screen?.id, DeviceWidth.MOBILE_DEFAULT_WIDTH)) // prettier-ignore
        }

        // Only update screen height in response to a "side-effect" component resize
        // TODO(michael-adalo): temporarily disabling due to multiple issues related to this instruction
        // and our bounds calculations/handling
        // if (positionInstructions.length === 0) {
        //   instructions.push(fitScreenHeightToComponents(screen.id))
        // }

        ;({ list } = applyInstructions(
          {
            list,
            pathMap: state.map,
            featureFlags: globalState.featureFlags,
          },
          instructions
        ))

        if (!skipSave) {
          // Only the parent screen is necessary here as saves will include all objects on the screen.
          const changedObjects = [id]
          saveTouched(state.appId, list, state.map, changedObjects)
        }

        const section = getSectionFromChild(list, state.map, oldDeviceObject)

        return {
          ...state,
          list,
          parentSelection: section ? [section.id] : [],
        }
      }

      // handle LEGACY updates
      let newObject = deepMerge(oldObject, changes)

      if (oldObject.type !== COMPONENT) {
        newObject = updateObjectWidth(newObject)
        newObject = translateChildren(newObject, oldObject)
      }

      if (oldObject.type === LIBRARY_COMPONENT) {
        const { libraryName, componentName } = newObject
        const globals = libraryGlobals[libraryName]?.[componentName]
        const openAccordion = resetAccordion ? null : getAccordionState(globalState, LIBRARY_INSPECT_GROUP) // prettier-ignore

        newObject = updateComponentBounds(newObject, globals, openAccordion, screen) // prettier-ignore
      }

      // prettier-ignore
      if (oldObject.type === LABEL && 'width' in object && oldObject.width !== object.width) {
        newObject.autoWidth = false
      }

      list = update(list, path, newObject)
      list = updateParentBounds(list, state.map, id, null, resizeParent, undefined, false) // prettier-ignore
      list = updateOptions(list, state.map, id)

      if (!skipSave) {
        saveTouched(state.appId, list, state.map, [id])
      }

      return {
        ...state,
        list,
      }
    }

    if (action.type === UPDATE_OBJECTS) {
      const globalState = action.globalState

      /** @type {import('utils/responsiveTypes').EditorObject[]} */
      const objects = action.objects

      /** @type {boolean} */
      const magicLayout = state.magicLayout

      /** @type {import('../types/ObjectList').ObjectList} */
      let list = state.list

      const hasAutoCustomLayout = !!getFeatureFlag(globalState, 'hasAutoCustomLayout') // prettier-ignore

      for (const object of objects) {
        const { id } = object
        const path = state.map[id]

        /** @type {import('utils/responsiveTypes').EditorObject} */
        const screen = getObject(list, subPath(path, 1))

        /** @type {import('utils/responsiveTypes').EditorObject} */
        const oldObject = getObject(state.list, path)
        /** @type {Partial<import('utils/responsiveTypes').EditorObject} */
        const changes = evaluate(object, oldObject)

        evaluateUserflowEvents(
          [USERFLOW_EVENTS.LIST_CONNECTED, USERFLOW_EVENTS.LINK_ACTION_CREATED],
          state.appId,
          {
            object,
            oldObject,
          }
        ).catch(console.error)

        // handle RESPONSIVE updates
        if (magicLayout === true) {
          const device = getDeviceType(screen.width)
          const oldDeviceObject = getDeviceObject(oldObject, device)

          /** @type {import('../instructions/applyInstructions').Instruction[]} */
          const instructions = []

          const positionInstructions = inferInstructions(oldDeviceObject, changes) // prettier-ignore
          const hasLayoutInstruction = positionInstructions.some(i =>
            ['moveElement', 'resizeElement'].includes(i.operation)
          )

          if (
            hasAutoCustomLayout &&
            hasLayoutInstruction &&
            oldObject.type !== COMPONENT &&
            oldDeviceObject.initialDevice !== device
          ) {
            // With Auto Custom Layout on, before a layout change
            // in a device different from the initial one happens,
            // we enable device-specific layout in that device
            instructions.push(enableDeviceSpecificLayout(oldObject.id, device))
          }

          const { width, height, x, y, ...otherChanges } = changes

          if (!isEmpty(otherChanges)) {
            instructions.push(updateElement(oldObject.id, otherChanges))
          }

          if (positionInstructions.length > 0) {
            instructions.push(...positionInstructions)
          }

          if (oldObject.type !== COMPONENT) {
            instructions.push(resizeScreen(screen.id, screen.width))
          }

          // Only update screen height in response to a "side-effect" component resize
          // TODO(michael-adalo): temporarily disabling due to multiple issues related to this instruction
          // and our bounds calculations/handling
          // if (positionInstructions.length === 0) {
          //   instructions.push(fitScreenHeightToComponents(screen.id))
          // }

          ;({ list } = applyInstructions(
            {
              list,
              pathMap: state.map,
              featureFlags: globalState.featureFlags,
            },
            instructions
          ))

          continue
        }

        // handle LEGACY updates
        let newObject = { ...oldObject, ...changes }

        if (oldObject.type !== COMPONENT) {
          newObject = updateObjectWidth(newObject)
          newObject = translateChildren(newObject, oldObject)
        }

        if (oldObject.type === LIBRARY_COMPONENT) {
          const openAccordion = getAccordionState(globalState, LIBRARY_INSPECT_GROUP) // prettier-ignore

          newObject = updateComponentBounds(newObject, null, openAccordion, screen) // prettier-ignore
        }

        list = update(list, path, newObject)
        list = updateParentBounds(list, state.map, id, null, resizeParent, undefined, magicLayout) // prettier-ignore
        list = updateOptions(list, state.map, id)
      }

      saveTouched(
        state.appId,
        list,
        state.map,
        objects.map(obj => obj.id)
      )

      return {
        ...state,
        list,
      }
    }

    // TODO (michael-adalo): appears to be unused?
    if (action.type === CHANGE_OBJECT_TYPE) {
      const { id, newType } = action
      const { magicLayout } = state

      const path = state.map[id]
      let object = getObject(state.list, path)
      let { selection, map } = state

      if (newType === INPUT) {
        object = convertToInput(object)
      } else if (newType === LIST) {
        let children = [object]

        if (object.type === GROUP) {
          children = object.children || []
        }

        object = createEmptyObject(state.list, map, {
          children,
          height: 400,
          type: LIST,
        })

        selection = [object.id]
      } else {
        object = convertType(object, newType)
      }

      let list = update(state.list, path, object)
      map = remapSiblings(list, map, '0')

      if (object.id !== id) {
        list = updateParentBounds(
          list,
          map,
          object.children[0].id,
          null,
          resizeParent,
          undefined,
          magicLayout
        )
      }

      if (newType === LIST) {
        const { id } = object
        let obj = getObject(list, map[id])
        obj = { ...obj, height: obj.height * 3 }
        list = update(list, map[id], obj)
      }

      saveTouched(state.appId, list, map, [id])

      return updateIndices({
        ...state,
        selection,
        map,
        list,
      })
    }

    if (action.type === POSITION_OBJECTS) {
      const { ids, offset, shouldSave, globalState } = action

      const { magicLayout } = state
      let list = state.list

      const objects = getSelectedSub(state, ids)

      const hasAutoCustomLayout = getFeatureFlag(
        globalState,
        'hasAutoCustomLayout'
      )

      for (const object of objects) {
        if (magicLayout) {
          // transform section elements (layout_helper/container) into layout_section
          // non-section-related elements will return original object
          const obj = getSectionFromSectionElement(list, state.map, object)

          const screen = getParentScreen(list, state.map, obj.id)
          const device = screen ? getDeviceType(screen.width) : undefined
          const { x, y } = getDeviceObject(obj, device)

          const finalX = x + offset.x
          const finalY = y + offset.y

          const instructions = []
          if (obj.type === COMPONENT) {
            instructions.push(moveScreen(obj.id, finalX, finalY))
          } else {
            if (hasAutoCustomLayout && device !== obj.initialDevice) {
              // With Auto Custom Layout on, before a layout change in a device different from the initial one happens, we enable device-specific layout in that device
              instructions.push(enableDeviceSpecificLayout(obj.id, device))
            }
            instructions.push(moveElement(obj.id, finalX, finalY))
          }

          ;({ list } = applyInstructions(
            {
              list,
              pathMap: state.map,
              featureFlags: globalState.featureFlags,
            },
            instructions
          ))
        } else {
          let newObj = {
            ...object,
            x: object.x + offset.x,
            y: object.y + offset.y,
          }

          if (object.type !== COMPONENT) {
            newObj = translateChildren(newObj, object)
          }

          list = update(list, state.map[object.id], newObj)
          list = updateParentBounds(
            list,
            state.map,
            object.id,
            null,
            resizeParent
          )
        }
      }

      if (shouldSave) {
        saveTouched(state.appId, list, state.map, ids)
      }

      return {
        ...state,
        list,
      }
    }

    if (action.type === ALIGN_OBJECTS) {
      const { selection, appId, map } = state
      let { list, magicLayout } = state
      const { direction } = action
      const objects = selection.map(id => getObject(list, map[id]))
      const absoluteObjects = objects.map(o => getAbsoluteBbox(o, list, map))

      const bbox =
        objects.length === 1
          ? getObject(list, subPath(map[objects[0].id], 1))
          : getBoundingBox(absoluteObjects)

      objects.forEach((obj, i) => {
        let newObj = alignToRect(obj, absoluteObjects[i], bbox, direction)

        if (newObj.type !== COMPONENT) {
          newObj = translateChildren(newObj, obj)
        }

        list = update(list, map[obj.id], newObj)
        list = updateParentBounds(
          list,
          map,
          obj.id,
          null,
          resizeParent,
          undefined,
          magicLayout
        )
      })

      saveTouched(appId, list, map, selection)

      return {
        ...state,
        list,
      }
    }

    if (action.type === DELETE_OBJECT) {
      const { id } = action

      return performDelete(state, id)
    }

    if (action.type === REORDER_OBJECTS) {
      const { ids, dropTarget, options } = action
      let { list, map, magicLayout } = state

      const { dropAfter, dropInside } = options

      let dropPath = map[dropTarget]
      const affectedScreenPaths = [subPath(dropPath, 1)]

      if (dropInside) {
        const children = getObject(list, dropPath).children

        if (!children) {
          list = update(list, dropPath, {
            ...getObject(list, dropPath),
            children: [],
          })
        }

        const childCount = children ? children.length : 0
        dropPath = joinPaths(dropPath, `${childCount}`)
        affectedScreenPaths.push(subPath(dropPath, 1))
      }

      if (!dropAfter) {
        dropPath = incrementPath(dropPath)
      }

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      const itemParentPaths = itemPaths.map(path => subPath(path, 1))
      affectedScreenPaths.concat(itemParentPaths)

      for (let i = 0; i < itemPaths.length; i += 1) {
        const path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      const items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(
            list,
            map,
            null,
            path,
            resizeParent,
            undefined,
            magicLayout
          )
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(
          list,
          map,
          itm.id,
          null,
          resizeParent,
          undefined,
          magicLayout
        )
        list = updateOptions(list, map, itm.id)
      })

      // Section rule: it's not possible for a Section to be a child of another component
      for (const id of ids) {
        const object = getObject(list, map[id])
        const objectParent = getParent(list, map, id)

        if (object.type === LAYOUT_SECTION && objectParent.type !== COMPONENT) {
          return state
        }
      }

      // Section rule: it's not possible for a component to be dropped alongside a layout helper or container
      const siblings = getSiblings(list, dropPath)
      for (const sibling of siblings) {
        if (isSectionElement(sibling)) {
          return state
        }
      }

      if (magicLayout) {
        const affectedScreenIds = affectedScreenPaths
          .map(screenPath => {
            const screen = getObject(list, screenPath)

            return screen?.id
          })
          .filter(id => id !== undefined)
        const uniqueScreenIds = Array.from(new Set(affectedScreenIds))
        for (const screenId of uniqueScreenIds) {
          list = calculatePushGraphs(list, map, screenId)
        }
      }

      const affectedPaths = itemPaths.concat([dropPath, ...affectedScreenPaths])
      saveTouched(state.appId, list, map, null, affectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_FIRST) {
      const { ids } = action
      let { list, map, magicLayout } = state

      const actualPath = map[ids]

      let dropPath = `${subPath(actualPath, pathLength(actualPath) - 1)}.9999`

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        const path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      const items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(
            list,
            map,
            null,
            path,
            resizeParent,
            undefined,
            magicLayout
          )
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(
          list,
          map,
          itm.id,
          null,
          resizeParent,
          undefined,
          magicLayout
        )
        list = updateOptions(list, map, itm.id)
      })

      const effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_LAST) {
      const { ids } = action
      let { list, map, magicLayout } = state

      const actualPath = map[ids]

      let dropPath = `${subPath(actualPath, pathLength(actualPath) - 1)}.0`

      const lastLayerCheck = actualPath === dropPath

      if (lastLayerCheck) {
        return state
      }

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        const path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      const items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(
            list,
            map,
            null,
            path,
            resizeParent,
            undefined,
            magicLayout
          )
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(
          list,
          map,
          itm.id,
          null,
          resizeParent,
          undefined,
          magicLayout
        )
        list = updateOptions(list, map, itm.id)
      })

      const effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_UP) {
      const { ids } = action
      let { list, map, magicLayout } = state

      const actualPath = map[ids]

      if (!actualPath) {
        return state
      }

      let dropPath = incrementPath(actualPath)
      dropPath = incrementPath(dropPath)

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        const path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      const items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(
            list,
            map,
            null,
            path,
            resizeParent,
            undefined,
            magicLayout
          )
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(
          list,
          map,
          itm.id,
          null,
          resizeParent,
          undefined,
          magicLayout
        )
        list = updateOptions(list, map, itm.id)
      })

      const effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (action.type === REORDER_OBJECTS_MOVE_DOWN) {
      const { ids } = action
      let { list, map, magicLayout } = state

      const actualPath = map[ids]

      const lastLayerCheck = actualPath === '0.0'

      if (lastLayerCheck) {
        return state
      }

      let dropPath = decrementPath(actualPath)

      let itemPaths = ids.map(id => map[id]).filter(i => i)

      for (let i = 0; i < itemPaths.length; i += 1) {
        const path = itemPaths[i]

        if (isChildPath(path, dropPath)) {
          return state
        }
      }

      itemPaths = sortPaths(itemPaths)
      const items = itemPaths.map(path => getObject(list, path))

      dropPath = getDropPath(dropPath, itemPaths)

      itemPaths
        .slice()
        .reverse()
        .forEach(path => {
          list = remove(list, path)
          list = updateParentBounds(
            list,
            map,
            null,
            path,
            resizeParent,
            undefined,
            magicLayout
          )
          list = updateParentOptions(list, map, null, path)
        })

      list = insert(list, dropPath, ...items)
      map = remapSiblings(list, map, '0')

      items.forEach(itm => {
        list = updateParentBounds(
          list,
          map,
          itm.id,
          null,
          resizeParent,
          undefined,
          magicLayout
        )
        list = updateOptions(list, map, itm.id)
      })

      const effectedPaths = itemPaths.concat([dropPath])
      saveTouched(state.appId, list, map, null, effectedPaths)

      return {
        ...state,
        list,
        map,
        hoverSelection: [],
      }
    }

    if (
      action.type === GROUP_OBJECTS ||
      action.type === GROUP_OBJECTS_TO_LIST
    ) {
      let { list, map, magicLayout } = state
      const { ids } = action

      if (ids.length === 0) {
        return state
      }

      let paths = ids.map(id => map[id])
      paths = paths.filter(path => pathLength(path) > 1)
      paths = removeChildren(sortPaths(paths))

      const group = createEmptyObject(state.list, map, {
        type: action.type === GROUP_OBJECTS_TO_LIST ? LIST : GROUP,
        children: [],
        width: 0,
        height: 0,
      })

      const groupPath = getGroupPath(paths)

      if (!groupPath) {
        return
      }

      paths.forEach(path => {
        const obj = getObject(list, path)
        group.children.push(obj)
      })

      const pathsReversed = [...paths]
      pathsReversed.reverse()

      pathsReversed.forEach(path => {
        list = remove(list, path)
      })

      list = insert(list, groupPath, group)
      map = remapSiblings(list, map, groupPath)

      list = updateBounds(
        list,
        map,
        groupPath,
        resizeParent,
        undefined,
        magicLayout
      )

      if (action.type === GROUP_OBJECTS_TO_LIST) {
        let group = getObject(list, groupPath)

        group = {
          ...group,
          height: group.height * 3 + (group.rowMargin || 0) * 2,
        }

        list = update(list, groupPath, group)
      }

      const selection = [group.id]

      saveTouched(state.appId, list, map, null, paths)

      return updateIndices({
        ...state,
        list,
        map,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === UNGROUP_OBJECTS) {
      const { ids } = action
      let { list, map } = state

      const paths = ids.map(id => map[id])
      let objects = paths.map(path => getObject(list, path))

      objects = objects.filter(obj => obj.type === GROUP)

      let selection = []

      objects.forEach(group => {
        const { id, children } = group
        const path = map[id]

        selection = selection.concat(children.map(c => c.id))

        list = remove(list, path)
        list = insert(list, path, ...children)
        map = remapSiblings(list, map, '0')
      })

      saveTouched(state.appId, list, map, null, paths)

      return updateIndices({
        ...state,
        list,
        map,
        selection,
      })
    }

    if (action.type === ZOOM) {
      const { relativeScale, relativeOffset } = action
      let { scale, offset } = action

      const newState = { ...state.zoom }

      if (relativeScale) {
        scale = state.zoom.scale * relativeScale
      }

      if (relativeOffset) {
        const [diffX, diffY] = relativeOffset
        const [x, y] = state.zoom.offset

        offset = [x + diffX, y + diffY]
      }

      if (scale && !offset) {
        const xCenter = (window.innerWidth - 430) / 2 + 430
        const yCenter = (window.innerHeight - 64) / 2 + 64
        const center = [xCenter, yCenter]

        offset = getOffset(scale, center, state.zoom.scale, state.zoom.offset)
      }

      if (scale) {
        newState.scale = scale
      }

      if (offset) {
        newState.offset = offset
      }

      if (newState.scale > 64 || newState.scale < 1.0 / 16) {
        return state
      }

      return {
        ...state,
        zoomAppId: state.appId,
        zoom: newState,
      }
    }

    if (action.type === RESET_ZOOM) {
      const { list } = state
      const zoom = calculateZoom(getBoundingBox(list))

      return { ...state, zoom }
    }

    if (action.type === '@@redux-undo/UNDO') {
      saveDiffed(state.appId, state.list)
    } else if (action.type === '@@redux-undo/REDO') {
      saveDiffed(state.appId, state.list)
    }

    if (action.type === SET_TOOL) {
      return {
        ...state,
      }
    }

    if (action.type === SELECT_PARENT) {
      const { objectId } = action

      return {
        ...state,
        selectedParent: objectId,
      }
    }

    if (action.type === PAN) {
      return {
        ...state,
        panning: action.value,
      }
    }

    if (action.type === SET_LIBRARY_GLOBAL) {
      const { appId } = state
      let { libraryGlobals } = state
      const { libraryName, componentName, changes } = action

      libraryGlobals = {
        ...state.libraryGlobals,
        [libraryName]: {
          ...state.libraryGlobals[libraryName],
          [componentName]: deepMerge(
            state.libraryGlobals[libraryName]?.[componentName],
            changes
          ),
        },
      }

      saveLibraryGlobals(appId, libraryGlobals)

      return { ...state, libraryGlobals }
    }

    if (action.type === RUN_INSTRUCTIONS) {
      const { instructions, globalState } = action
      const app = getApp(globalState, state.appId)

      const instructionState = {
        list: state.list,
        pathMap: state.map,
        selection: state.selection,
        featureFlags: globalState.featureFlags,
        app,
      }

      const { list, pathMap, selection } = applyInstructions(
        instructionState,
        instructions
      )

      // TODO (Tom) Skip Save when?
      const touched = [...selection, ...getTouchedFromInstruction(instructions)]
      if (touched.length > 0) {
        saveTouched(state.appId, list, pathMap, touched)
      }

      return updateIndices({
        ...state,
        list,
        map: pathMap,
        selection,
        hoverSelection: [],
      })
    }

    if (action.type === RUN_SELECTION_INSTRUCTION) {
      // one instruction at a time
      const { instruction, globalState } = action

      // prevent running instructions on section elements
      if (Array.isArray(instruction.options?.objectIds)) {
        for (const objectId of instruction.options.objectIds) {
          const object = getObject(state.list, state.map[objectId])

          if (isSectionElement(object)) {
            return state
          }
        }
      }

      // the only thing that will change is the list,
      // since we're not changing the object hierarchy or selection
      const { list } = applyLayoutInstruction(
        {
          list: state.list,
          pathMap: state.map,
          selection: state.selection,
          featureFlags: globalState.featureFlags,
        },
        instruction
      )

      const touched = getSelectionTouchedFromInstruction(instruction)
      if (touched.length > 0) {
        saveTouched(state.appId, list, state.map, touched)
      }

      return {
        ...state,
        list,
      }
    }

    return state
  }
)
