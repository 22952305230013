import React, { Component } from 'react'

import DummyAction from './Form/DummyAction'
import DummyActionForm from './Form/DummyActionForm'
import Form from './Form'

export default class ActionItem extends Component {
  render() {
    const {
      id,
      action,
      appId,
      componentId,
      objectId,
      onRemove,
      onUpdate,
      reference,
      dummyActions,
      onCreateFirst,
    } = this.props

    return (
      <div className="action-item">
        {dummyActions
          ? dummyActions.map(action => (
              <DummyAction
                key={action.id}
                dummyAction={action}
                objectId={objectId}
              />
            ))
          : null}
        {action ? (
          <Form
            form={`action-form-${id}`}
            onChange={onUpdate}
            initialValues={action}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
            onRemove={onRemove}
            reference={reference}
            dummyActions={dummyActions}
          />
        ) : (
          <DummyActionForm
            onAdd={onCreateFirst}
            dummyActions={dummyActions}
            appId={appId}
            componentId={componentId}
            objectId={objectId}
          />
        )}
      </div>
    )
  }
}
