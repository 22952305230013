import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Jotform from 'jotform-react'

import { getCurrentOrganization } from 'ducks/organizations'
import { getCurrentUser } from 'ducks/users'

import Modal from '../Modal'

import './ChurnFormModal.scss'

interface ModalInput {
  onSuccess: { (value: boolean): void }
}

const ChurnFormModal = ({ onSuccess }: ModalInput): JSX.Element => {
  const { appId } = useParams<{ appId: string }>()
  const currentUser = useSelector(getCurrentUser)
  const currentOrganization = useSelector(getCurrentOrganization)
  const history = useHistory()

  const fields = {
    email: currentUser?.email,
    fullName: currentUser?.name,
    appId,
    plan: currentOrganization?.planType,
    persona: currentUser?.persona,
    signUp: currentUser?.createdAt,
  } as Record<string, string>

  const handleSubmit = () => {
    onSuccess(true)
    history.push(`/apps/${appId}/unsubscribe-success`)
  }

  return (
    <Modal onClose={() => {}} className="churn-form-modal">
      <Modal.Content>
        <Jotform
          formURL={`https://form.jotform.com/241703587556060?${new URLSearchParams(
            {
              source: 'app',
              ...fields,
            }
          ).toString()}`}
          onSubmit={handleSubmit}
          autoResize
        />
      </Modal.Content>
    </Modal>
  )
}

export default ChurnFormModal
