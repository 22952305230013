/* eslint-disable no-param-reassign */
import { cloneDeep } from 'lodash'
import { DeviceType, DeviceWidth, LAYOUT_SECTION } from '@adalo/constants'
import { getId, update } from '@adalo/utils'
import { ObjectList } from 'ducks/editor/types/ObjectList'
import { ObjectPathMap } from 'ducks/editor/types/ObjectPathMap'
import {
  applyInstructions,
  moveElement,
  resizeElement,
  resizeScreen,
} from 'ducks/editor/instructions'
import getObject from 'ducks/editor/objects/helpers/getObject'
import { EditorObject } from 'utils/responsiveTypes'
import traverseChildren from 'utils/objects/traverseChildren'
import { changeObjectParent } from 'utils/positioning'
import getDeviceObject from 'utils/getDeviceObject'
import usesSharedLayout from 'utils/objects/usesSharedLayout'

import { replaceAllOccurrencesFromMap } from './utils'
import {
  getContainerChildrenFromSection,
  getContainerFromSection,
  getLayoutHelperFromSection,
} from '..'
import getLayoutSectionFitForScreen from './getLayoutSectionFitForScreen'

const devices = Object.values(DeviceType)

const SCREEN_SIZES = {
  [DeviceType.MOBILE]: DeviceWidth.MOBILE_DEFAULT_WIDTH,
  [DeviceType.TABLET]: DeviceWidth.TABLET_DEFAULT_WIDTH,
  [DeviceType.DESKTOP]: DeviceWidth.DESKTOP_DEFAULT_WIDTH,
}

/**
 * Removes the left and right properties if no min/max width properties are set
 * We assume that if left and right are set, then min/max width should be set as well
 * Our layout logic will only set left and right if min/max width properties are set
 * Therefore if no min/max width properties are set, we remove left and right as those values could now be stale
 */
export const removeStaleLeftRightPropertiesIfNoMinMaxWidthSet = (
  object: EditorObject
): void => {
  if (typeof object.left === 'number' && typeof object.right === 'number') {
    if (
      typeof object.minWidth !== 'number' &&
      typeof object.minWidthEnabled !== 'boolean' &&
      typeof object.maxWidth !== 'number' &&
      typeof object.maxWidthEnabled !== 'boolean'
    ) {
      delete object.left
      delete object.right
    }
  }

  for (const device of devices) {
    if (
      typeof object[device]?.left === 'number' &&
      typeof object[device]?.right === 'number'
    ) {
      if (
        typeof object[device]?.minWidth !== 'number' &&
        typeof object[device]?.minWidthEnabled !== 'boolean' &&
        typeof object[device]?.maxWidth !== 'number' &&
        typeof object[device]?.maxWidthEnabled !== 'boolean'
      ) {
        delete object[device]?.left
        delete object[device]?.right
      }
    }
  }
}

type Result = { list: ObjectList; pathMap: ObjectPathMap }

/**
 * Creates a new layout section from the data of the sidebar layout sections with preset children
 */
const createLayoutSectionFromPrebuilt = (
  list: ObjectList,
  pathMap: ObjectPathMap,
  screen: EditorObject,
  object: EditorObject
): Result => {
  const fitForScreen = getLayoutSectionFitForScreen(screen)
  const innerSectionChildren = getContainerChildrenFromSection(object)

  if (object.type !== LAYOUT_SECTION) {
    throw new Error(
      `Tried to create a new preset layout section from a non-layout section object`
    )
  }

  if (!innerSectionChildren || innerSectionChildren.length === 0) {
    throw new Error(
      `Tried to create a new layout section from a preset with no children`
    )
  }

  const newObject = cloneDeep(object)
  const newSharedY = newObject.y

  removeStaleLeftRightPropertiesIfNoMinMaxWidthSet(newObject)

  // newObject already has an updated id
  const idMap: Record<string, string> = {
    [newObject.id]: newObject.id,
  }

  // translate all children positions on all devices
  // replace ids & save on map to replace in pushGraphs and other references later on
  traverseChildren(newObject, (item: EditorObject) => {
    if (!item.type || !item.id) {
      return
    }

    removeStaleLeftRightPropertiesIfNoMinMaxWidthSet(item)

    let newId = idMap[item.id]

    if (!newId) {
      newId = getId() as string
      idMap[item.id] = newId
    }

    item.id = newId
  })

  // replace all ids from map
  const objectWithUpdatedIds = replaceAllOccurrencesFromMap(newObject, idMap)

  let updatedList = update(
    list,
    pathMap[objectWithUpdatedIds.id],
    objectWithUpdatedIds
  ) as ObjectList
  let updatedPathMap = cloneDeep(pathMap)

  const layoutSection = getObject(updatedList, updatedPathMap, newObject.id)
  const layoutHelper = getLayoutHelperFromSection(layoutSection)
  const container = getContainerFromSection(layoutSection)

  ;[updatedList, updatedPathMap] = changeObjectParent(
    updatedList,
    updatedPathMap,
    layoutHelper.id,
    layoutSection.id
  ) as [ObjectList, ObjectPathMap]
  ;[updatedList, updatedPathMap] = changeObjectParent(
    updatedList,
    updatedPathMap,
    container.id,
    layoutHelper.id
  ) as [ObjectList, ObjectPathMap]

  // despite pre-built sections usually having custom layout switched on for all devices
  // a negative yOffset on the shared layout can cause issues with the layout
  for (const device of devices) {
    const layoutSectionScoped = getObject(updatedList, updatedPathMap, layoutSection.id) // prettier-ignore
    if (!usesSharedLayout(layoutSectionScoped, device)) {
      updatedList = update(updatedList, updatedPathMap[layoutSection.id], {
        ...layoutSectionScoped,
        y: newSharedY,
      }) as ObjectList
    }

    const layoutHelperScoped = getObject(updatedList, updatedPathMap, layoutHelper.id) // prettier-ignore
    if (!usesSharedLayout(layoutHelperScoped, device)) {
      updatedList = update(updatedList, updatedPathMap[layoutHelper.id], {
        ...layoutHelperScoped,
        y: newSharedY,
      }) as ObjectList
    }

    const containerScoped = getObject(updatedList, updatedPathMap, container.id) // prettier-ignore
    if (!usesSharedLayout(containerScoped, device)) {
      updatedList = update(updatedList, updatedPathMap[container.id], {
        ...containerScoped,
        y: newSharedY,
      }) as ObjectList
    }
  }

  const instructions = []

  for (const device of devices) {
    const originalDeviceObject = getDeviceObject(object, device)
    const layoutSectionScoped = getObject(
      updatedList,
      updatedPathMap,
      layoutSection.id
    )
    const deviceObject = getDeviceObject(layoutSectionScoped, device)

    instructions.push(
      resizeScreen(screen.id, SCREEN_SIZES[device]),
      moveElement(
        layoutSectionScoped.id,
        fitForScreen[device]?.x ?? deviceObject.x,
        newSharedY
      ),
      resizeElement(
        layoutSectionScoped.id,
        fitForScreen[device]?.width ?? SCREEN_SIZES[device],
        // force to the height of the original object layout section
        originalDeviceObject.height
      )
    )
  }

  instructions.push(resizeScreen(screen.id, screen.width))
  ;({ list: updatedList, pathMap: updatedPathMap } = applyInstructions(
    { list: updatedList, pathMap: updatedPathMap, selection: [] },
    instructions
  ))

  return {
    list: updatedList,
    pathMap: updatedPathMap,
  }
}

export default createLayoutSectionFromPrebuilt
