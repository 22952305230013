import {
  actionTypes,
  bindingTypes,
  dataTypes,
  ACTION,
  LIBRARY_COMPONENT,
  FORM,
} from '@adalo/constants'

import { newRemapSiblings, pathLength, subPath, getObject } from '@adalo/utils'

import { getAppComponent, hasRole } from './libraries'
import { getIndividualActions } from './actions'
import { getObjectName } from './naming'
import { getTableInfo } from './sources'

// Iterates through components and creates map of
// componentId => components linking to it
export const buildLinkMap = app => {
  const map = {}

  for (const id of Object.keys(app.components)) {
    const component = app.components[id]
    const actions = component.actions ?? {}

    // Actions
    for (const objectId of Object.keys(actions)) {
      const actions = component.actions[objectId]

      const improperlyBuiltAction = Array.isArray(actions)

      if (improperlyBuiltAction) {
        continue
      }

      for (const actionGroupId of Object.keys(actions)) {
        if (!Array.isArray(actions[actionGroupId]?.actions)) {
          continue
        }

        for (const actionObj of actions[actionGroupId].actions) {
          if (!actionObj) continue
          if (actionObj.actionType === actionTypes.NAVIGATE) {
            const { target } = actionObj.options ?? {}

            if (target) {
              if (!map[target]) {
                map[target] = []
              }

              map[target].push(id)
            }
          }
        }
      }
    }
  }

  const result = {}

  for (const id of Object.keys(map)) {
    result[id] = Array.from(new Set(map[id]))
  }

  return result
}

export const getContextualTables = (
  app,
  componentId,
  inboundLinks,
  visited = {},
  cache = {},
  includePartials = false,
  fromScreenId,
  originComponent,
  numOfRecursiveCalls = 0
) => {
  if (visited[componentId]) {
    return null
  }

  if (cache[componentId]) {
    return cache[componentId]
  }

  visited[componentId] = true

  if (
    app?.authComponentId === componentId ||
    app?.launchComponentId === componentId
  ) {
    return []
  }

  const results = []

  // Get links to this component
  // For each link:
  // - Analyze positional list elements
  // - Recursively fetch inbound links
  // Do not visit the same node twice (this will prevent looping)
  let links = inboundLinks[componentId] || []

  // When a fromScreenId is passed, will filter the links
  // from this screen to the target
  if (fromScreenId) {
    links = links.filter(link => link === fromScreenId)
  } else {
    links = links.filter(parentId => app.components[parentId])
  }

  for (const parentId of links) {
    const pageResults = []
    const parentComponent = app.components[parentId]

    const map = newRemapSiblings(parentComponent.objects)

    // Navigate Actions
    for (const objectId of Object.keys(parentComponent.actions ?? {})) {
      const actions = getIndividualActions(parentComponent.actions[objectId])
      const path = map.get(objectId)
      const actionPathLength = pathLength(path)
      // When a originComponent is passed, this function will return
      // the collections from only this component linking to the target.
      // otherwise it would return all collections from the origin screen
      if (originComponent && objectId !== originComponent) continue

      for (
        let actionIndex = 0;
        actionIndex < actions.length;
        actionIndex += 1
      ) {
        const action = actions[actionIndex]
        if (action.actionType !== actionTypes.NAVIGATE) {
          continue
        }

        if (action.options?.target !== componentId) {
          continue
        }
        // Lists
        //
        // 1. Get path
        // 2. Iterate through parents, and get bindings
        //     - start with close parents, then further
        // 3. Check each binding to get the table
        // 4. Push datasource / table into results
        for (let pathIndex = 1; pathIndex <= actionPathLength; pathIndex += 1) {
          const obj = getObject(
            parentComponent.objects,
            subPath(path, pathIndex)
          )

          const binding = obj && obj.dataBinding

          if (binding && binding.bindingType === bindingTypes.LIST) {
            const source = binding.source

            const result = {
              ...getTableInfo(source),
              meta: {
                componentName: getObjectName(parentComponent),
                objectName: getObjectName(obj),
              },
            }

            pageResults.push(result)
          }
        }

        const object = getObject(parentComponent.objects, path)

        // Creates
        //
        // 1. Get actions
        // 2. Look at prior actions, and look for creates
        // 3. Get table info, and add

        for (const prevAction of actions.slice(0, actionIndex)) {
          if (
            prevAction.actionType === actionTypes.CREATE_OBJECT &&
            prevAction.options &&
            prevAction.options.datasourceId &&
            (prevAction.options.tableId || prevAction.options.collectionId)
          ) {
            pageResults.push({
              datasourceId: prevAction.options.datasourceId,
              tableId: prevAction.options.tableId,
              collectionId: prevAction.options.collectionId,
              meta: {
                componentName: getObjectName(parentComponent),
                objectName: getObjectName(object),
              },
            })
          }
        }

        // Creates in forms
        if (object && object.type === FORM) {
          const { reference, collection } = object

          if (reference === 'new' && collection) {
            pageResults.push({
              ...collection,
              meta: {
                componentName: getObjectName(parentComponent),
                objectName: getObjectName(object),
              },
            })
          }
        }

        // Libraries
        // Check library components for lists
        // Try to find action refs where actionId is action.actionId

        if (object && object.type === LIBRARY_COMPONENT) {
          const listProps = Object.keys(object.attributes).filter(key => {
            const attr = object.attributes[key]

            return (
              attr &&
              attr.type === 'binding' &&
              attr.source.dataType === dataTypes.LIST
            )
          })

          const component = getAppComponent(
            app,
            object.libraryName,
            object.componentName
          )

          if (component) {
            for (const prop of listProps) {
              // First, check props
              let relatedProps = component.props
                .filter(sibling => {
                  return (
                    hasRole(sibling, 'listItem') &&
                    sibling.reference === prop &&
                    sibling.type === ACTION &&
                    object.attributes[sibling.name]
                  )
                })
                .map(s => object.attributes[s.name])

              // Then check child components
              const children = component.childComponents || []

              const relatedChildren = children.filter(
                child => hasRole(child, 'listItem') && child.reference === prop
              )

              relatedProps = relatedProps.concat(
                ...relatedChildren.map(c => {
                  return c.props
                    .filter(prop => {
                      return (
                        prop.type === ACTION &&
                        object.attributes[c.name] &&
                        object.attributes[c.name][prop.name]
                      )
                    })
                    .map(prop => object.attributes[c.name][prop.name])
                })
              )

              const hasLink = relatedProps.some(
                ref => ref.actionId === action.actionId
              )

              if (hasLink) {
                const binding = object.attributes[prop]
                const source = binding.source

                pageResults.push({
                  ...getTableInfo(source),
                  meta: {
                    componentName: getObjectName(parentComponent),
                    objectName: getObjectName(object),
                  },
                })
              }
            }
          }
        }
      }
    }

    numOfRecursiveCalls += 1

    if (numOfRecursiveCalls < 35) {
      const inheritedResults = getContextualTables(
        app,
        parentId,
        inboundLinks,
        visited,
        cache,
        false,
        undefined,
        undefined,
        numOfRecursiveCalls
      )

      if (inheritedResults === null && pageResults.length === 0) {
        continue
      } else if (inheritedResults) {
        pageResults.push(...inheritedResults)
      }
    }

    results.push(
      pageResults.map(itm => ({
        ...itm,
        componentId: parentId,
      }))
    )
  }

  // Flatten results

  const resultsMap = {}
  const firstResult = results[0]

  if (!firstResult) {
    if (links.length === 0) {
      return []
    }

    return null
  }

  // First page
  firstResult.forEach(result => {
    resultsMap[resultKey(result)] = {
      ...result,
      components: [result.componentId],
    }
  })

  // Others
  results.slice(1).forEach((pageResults, i) => {
    const keys = pageResults.map(result => resultKey(result))

    // Partials will use OR instead of AND on results
    if (includePartials) {
      for (const result of pageResults) {
        const key = resultKey(result)

        if (resultsMap[key]) {
          resultsMap[key] = {
            ...resultsMap[key],
            components: [...resultsMap[key].components, result.componentId],
          }
        } else {
          resultsMap[key] = {
            ...result,
            components: [result.componentId],
          }
        }
      }
    } else {
      for (const key of Object.keys(resultsMap)) {
        if (!keys.includes(key)) {
          delete resultsMap[key]
        }
      }
    }
  })

  const extrasMap = {}

  // Get stragglers
  if (!includePartials) {
    const extras = []

    results.forEach((pageResults, i) => {
      const pageExtras = []

      for (const result of pageResults) {
        if (!resultsMap[resultKey(result)]) {
          pageExtras.push(result)

          if (!result.isBelongsTo) {
            pageExtras.push(...getBelongsToResults(app, result))
          }
        }
      }

      extras.push(pageExtras)
    })

    // Repeat add & reduce
    extras[0].forEach(result => {
      extrasMap[resultKey(result)] = result
    })

    // Others again
    extras.slice(1).forEach((pageResults, i) => {
      const keys = pageResults.map(result => resultKey(result))

      for (const key of Object.keys(extrasMap)) {
        if (!keys.includes(key)) {
          delete extrasMap[key]
        }
      }
    })
  }

  const flatResults = Object.values({ ...extrasMap, ...resultsMap })

  // De-dup results

  const seen = {}
  const uniqueResults = []

  flatResults.forEach(result => {
    const key = resultKey(result)

    if (seen[key]) {
      return
    }

    seen[key] = true

    uniqueResults.push(result)
  })

  cache[componentId] = uniqueResults

  return uniqueResults
}

const getBelongsToResults = (app, result) => {
  const { datasourceId, tableId } = result
  const datasource = app.datasources[datasourceId]

  if (datasource?.type !== 'apto-backend') {
    return []
  }

  const table = datasource.tables[tableId]
  if (!table) return []

  const belongsToFields =
    table &&
    table.fields &&
    Object.keys(table.fields).filter(
      fieldId => table.fields[fieldId].type.type === 'belongsTo'
    )

  const results = []
  if (!belongsToFields) return results

  belongsToFields.forEach(fieldId => {
    const field = table.fields[fieldId]

    results.push({
      datasourceId,
      tableId: field.type.tableId,
      isBelongsTo: true,
    })
  })

  return results
}

const resultKey = result => `${result.datasourceId}.${result.tableId}`
